export const postRate = (builder) => ({
    postRate: builder.mutation({
      query: (credentials) => {
        const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
        return {
          url: '/assigned_route',
          method: 'POST',
          body: credentials,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token 
          },
        };
      },
    }),
  });