import React from "react";
import { Col, Row } from 'react-bootstrap';
import { GeneralInfoForm } from "components/UserOnboarding";


export default () => {

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      </div>

      <Row>
        <Col xs={12} xl={12}>
          <GeneralInfoForm />
        </Col>
      </Row>
    </>
  );
};
