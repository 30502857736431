export const deactivateWhatsTemplates = (builder) => ({
    deactivateWhatsTemplates: builder.mutation({
      query: ({ template = '' }) => {
        const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
        return {
          url: '/deactive_template',
          method: 'GET',
          params: { template },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          },
        }
      },
    }),
  });