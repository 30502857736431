//by shreya saraswat
import React, { Fragment, useEffect, useState } from "react";
import { HomeIcon } from "@heroicons/react/solid";
import { Row, Form, Button, InputGroup, ToastContainer, Toast, Breadcrumb, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { CurrencyRupeeIcon } from "@heroicons/react/outline";
import { MessageRateTable } from "components/Tables";
import { useGetRoutesListMutation, usePostRateMutation, useUserDropDownMutation } from "Services/services";
import { Routes } from "routes";



export default () => {

    //usertype
    // const userData = localStorage.getItem('TOKEN');
    // const userObject = JSON.parse(userData);
    // const userType = userObject.user_type;

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('success');

    const [refreshTable, setRefreshTable] = useState(false);

    const [peOptions, setPeOptions] = useState([]);
    const [user, setUser] = useState();
    const [channel, setChannel] = useState('');
    const [routeData, setRouteData] = useState({});
    const [options, setOptions] = useState('');
    const [routeName, setRouteName] = useState();
    const [fetchedCost, setFetchedCost] = useState();
    const [editedCost, setEditedCost] = useState();
    const [costError, setCostError] = useState('');

    const [userList] = useUserDropDownMutation();
    const [RoutesList] = useGetRoutesListMutation();

    // refresh all
    const refreshAll = () => {
        setUser();
        setChannel('');
        setRouteData({});
        setRouteName();
        setFetchedCost();
        setEditedCost();
        setCostError('');
    }

    // entity options set
    useEffect(() => {
        const fetchEntity = async () => {
            try {
                const response = await userList({ user_type: 4 });
                if (response?.data?.statusCode === 200) {
                    const userOptions = response?.data?.data?.user_details.map(option => ({
                        value: option.id,
                        label: option.user_name
                    }));
                    setPeOptions(userOptions);
                } else {
                    setPeOptions([]);
                }
            } catch (error) {
                setPeOptions([]);
            }
        };
        fetchEntity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // channel set
    const handleChannel = (e) => {
        setChannel(e.target.value);
    }
    // fetch ROUTE DATA on channel basis
    useEffect(() => {
        if (!channel) return; // Exit if channel is not set

        const fetchRouteName = async () => {
            try {
                const response = await RoutesList({ comm_type: channel });
                if (response?.data?.statusCode === 200) {
                    const fetchedData = response.data.data.rows;
                    setRouteData(fetchedData);

                    const userOptions = fetchedData.map(option => ({
                        value: option.id,
                        label: option.route_name
                    }));
                    setOptions(userOptions);
                } else {
                    setRouteData([]);
                    setOptions([]);
                }
            } catch (error) {
                setRouteData([]);
                setOptions([]);
            }
        };

        // Reset route name before fetching new data
        fetchRouteName();
        setRouteName('');
        setFetchedCost();
        setEditedCost();
        setCostError('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channel]);

    // Rate set as soon as Route Name set
    useEffect(() => {
        if (routeData && routeName) {
            const route = routeData.find(data => data.id === routeName.value);
            if (route) {
                let cost = route.cost;
                setFetchedCost(cost);
                setEditedCost();
                setCostError('');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routeName]);


    // FORM PART
    const calculatedValue = (cost) => {
        const value = (cost + cost * 0.1).toFixed(2); // increment by 10%
        return Number(value).toLocaleString(); // Format with commas
    };
    const handleChoice = (e) => {
        // Allow user to update the value
        const inputValue = e.target.value;
        if (/^\d+(\.\d{1,2})?$/.test(inputValue)) {
            setEditedCost(inputValue.replace(/,/g, '')); // Remove commas if present
        }
    };

    const validateValue = (value) => {
        const numericalValue = parseFloat(value.replace(/,/g, '')) || 0;
        const originalCost = fetchedCost;
        const minCost = originalCost + originalCost * 0.05;

        if (numericalValue < minCost) {
            setCostError(`Changed to Minimum Value instead of ${numericalValue}`);
            setEditedCost(minCost.toFixed(2));
        } else {
            setCostError('');
            setEditedCost(numericalValue.toLocaleString()); // Format with commas
        }
    };

    const [createRates] = usePostRateMutation();
    const sendRate = (e) => {
        e.preventDefault();

        if (!user || !routeName || !fetchedCost) {
            setToastMessage('Please Select all fields.');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }
        const postData = {
            assign_to: user.value,
            route_id: routeName.value,
            sell_price: editedCost || fetchedCost
        }
        createRates(postData)
            .then((success) => {
                if (success?.data?.status === 200) {
                    setToastMessage(success?.data?.message);
                    setToastVariant('success');
                    setRefreshTable(!refreshTable);
                    refreshAll();
                } else if (success?.data?.statusCode > 300) {
                    setToastMessage(success?.data?.message || 'Upload failed!');
                    setToastVariant('danger');
                }
                setShowToast(true);
            })
            .catch((error) => {
                setToastMessage(error?.data?.message || 'Upload failed');
                setToastVariant('danger');
                setShowToast(true);
            });
    }


    return (
        <Fragment>
            <ToastContainer position="top-end" className="p-3">
                <Toast
                    bg={toastVariant}
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    delay={3000}
                    autohide
                >
                    <Toast.Header>
                        <strong className="me-auto">Message Rate Creation</strong>
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>

            <div className="d-flex align-items-start my-3">
                <div className="d-block">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item linkAs={Link} to={Routes.Presentation.path}><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Message Rate</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Message Rate</h4>
                </div>
            </div>

            <Form border="0" className="card shadow p-4 mb-4" onSubmit={sendRate}>
                <Row>
                    <Form.Group as={Col} xs={12} md={6} lg={3} className="mb-3">
                        <Form.Label>Enterprise</Form.Label>
                        <Select
                            required
                            placeholder="--Select User--"
                            className="mb-0"
                            isSearchable={true}
                            options={peOptions}
                            value={user || ''}
                            onChange={(selectedOption) => {
                                setUser(selectedOption);
                            }}
                        />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} lg={3} className="mb-3">
                        <Form.Label>Message Type</Form.Label>
                        <Form.Select required value={channel} onChange={handleChannel}>
                            <option value="" disabled>--Select Channel--</option>
                            <option value="sms">SMS</option>
                            <option value="whatsapp">WhatsApp</option>
                            <option value="rcs">RCS</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} md={6} lg={3} className="mb-3">
                        <Form.Label>Route Name</Form.Label>
                        <Select
                            required
                            placeholder="--Select Route--"
                            className="mb-0"
                            isSearchable={true}
                            options={options}
                            value={routeName || ''}
                            onChange={(selectedOption) => {
                                setRouteName(selectedOption);
                            }}
                            isDisabled={!channel}
                        />
                    </Form.Group>

                    <Form.Group as={Col} xs={12} md={6} lg={3} className="mb-3">
                        <Form.Label>Rate</Form.Label>
                        <InputGroup>
                            <InputGroup.Text className="text-gray-600">
                                <CurrencyRupeeIcon className="icon icon-xs" />
                            </InputGroup.Text>
                            <Form.Control
                                required
                                type="text"
                                placeholder="1,000"
                                value={editedCost || calculatedValue(fetchedCost)}
                                onChange={(e) => handleChoice(e)}
                                onBlur={(e) => validateValue(e.target.value)}
                                disabled={!fetchedCost}
                                isInvalid={costError !== ''}
                            />
                        </InputGroup>
                        {costError !== '' && <p className="text-danger fs-6">{costError}</p>}
                    </Form.Group>

                    <Col xs={12}>
                        <Button variant="gray-800" className="animate-up-2 mt-2" type="submit">
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>

            <MessageRateTable key={refreshTable} />

        </Fragment >
    );
};
