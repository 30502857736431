//by shreya saraswat
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Col, Row, Form, Button, Dropdown, OverlayTrigger, Tooltip, Card, Modal, ProgressBar, Image } from 'react-bootstrap';
import { GrDocumentText } from "react-icons/gr";
import whatsappPic from "../../assets/img/mobileScreens/Whatsapp.png"
import { CheckCircleIcon, ChevronDownIcon, ClockIcon, DotsVerticalIcon, PencilIcon, PlusIcon, ShieldExclamationIcon, XIcon } from "@heroicons/react/solid";
import { MdOutlineContentCopy, MdOutlineDoNotDisturbAlt } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";
import { MdTextFields } from "react-icons/md";
import { ImImages } from "react-icons/im";
import { FcPlus } from "react-icons/fc";
import { MdOutlineVideocam } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import { DropImagesForm, DropMediaFilesForm, DropVideosForm } from "components/Forms";
import { XCircleIcon } from "@heroicons/react/outline";
import PhoneInput from "react-phone-input-2";
import { CreateCards, CreateForm } from "components/Modals";
import moment from "moment-timezone";
import { useCreateWhatsappMarketingMutation } from "Services/services";
import { useHistory } from "react-router-dom";
import { Routes } from "routes";
import { PiArrowSquareUpRight, PiPhone } from "react-icons/pi";
import { RiFileWord2Fill } from "react-icons/ri";
import { AiFillFilePdf } from "react-icons/ai";

export const WhatsappTemplateComponent = () => {

    const [showModal, setShowModal] = useState(false);
    const [progress, setProgress] = useState(0);
    const [reason, setReason] = useState('');
    const [variant, setVariant] = useState('primary');

    const formRef = useRef(null);
    const history = useHistory();
    // This will return the form input
    const handleCloseModal = () => {
        setShowModal(false);
        setTimeout(() => {
            setProgress(0);
        }, 100);
        setReason('');
        setVariant('primary');
    };
    // This will reset the form inputs
    const handleRefresh = () => {
        if (formRef.current) {
            formRef.current.reset();
        }
        setValidated(false);
        setShowModal(false);
        setTimeout(() => {
            setProgress(0);
        }, 100);
        history.push({
            pathname: Routes.Template.path,
            state: { activeTab: "WhatsApp" }
        });
        setReason('');
        setVariant('primary');
    };
    useEffect(() => {
        if (showModal) {
            const interval = setInterval(() => {
                setProgress(prevProgress => {
                    if (prevProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return Math.min(prevProgress + 2.5, 95);   //95 automatic, 100 only after API response
                });
            }, 100);
        }
    }, [showModal]);

    const [category, setCategory] = useState('');
    const handleCategory = (e) => {
        setCategory(e.target.value);
        if (e.target.value === 'Utility') {
            setFormat('interactive');
        }
    }
    //-------------->>>>>>>>>>>>>>>>>>>>> Authentication content start------------
    const [disclaimer, setDisclaimer] = useState(false);
    const [authValues, setAuthValues] = useState({ warning_expiryTime: '', disclaimer_otp: '' });
    const handleAuthValues = (e) => {
        const regexTime = /^(?:[0-9]|[1-8][0-9]|90)$/;
        const regexCode = /^\d{1,6}$/;
        if (e.target.name === 'warning_expiryTime') {
            if (regexTime.test(e.target.value)) {
                setAuthValues((prevContent) => ({
                    ...prevContent,
                    [e.target.name]: e.target.value
                }));
            }
        }
        else if (e.target.name === 'disclaimer_otp') {
            if (regexCode.test(e.target.value)) {
                setAuthValues((prevContent) => ({
                    ...prevContent,
                    [e.target.name]: e.target.value
                }));
            }
            // setAuthBody((prevValue) => ({
            //     ...prevValue,
            //     auth_body: (prevValue.auth_body || '').replace(`${authValues.disclaimer_otp || '{{1}}'} is your verification code. For Your Security, do not share this code.`, `${e.target.value} is your verification code. For Your Security, do not share this code.`)
            // }));
            // }
        }
    }
    const [authContent, setAuthContent] = useState({});
    // when we switch the options
    const handleAuthContent = (e) => {
        if (e.target.name === 'disclaimer') {
            setDisclaimer(!disclaimer);

            // if (e.target.checked) {
            //     setAuthBody((prevValue) => ({
            //         ...prevValue,
            //         auth_body: (prevValue.auth_body || '') + `${authValues.disclaimer_otp || '{{1}}'} is your verification code. For Your Security, do not share this code.`
            //     }));
            // } else {
            //     setAuthBody((prevValue) => ({
            //         ...prevValue,
            //         auth_body: (prevValue.auth_body || '').replace(`${authValues.disclaimer_otp || '{{1}}'} is your verification code. For Your Security, do not share this code.`, '')
            //     }));
            // }
            setAuthContent((prevContent) => ({
                ...prevContent,
                [e.target.name]: e.target.checked
            }));
        } else {
            setAuthContent((prevContent) => ({
                ...prevContent,
                [e.target.name]: e.target.checked
            }));
        }
    };
    // body and footer
    // const handleAuthBody = (e) => {
    //     const { name, value } = e.target;
    //     setAuthBody((prev) => ({
    //         ...prev,
    //         [name]: value,
    //     }));
    // };

    // //variables
    // const [authInputFields, setAuthInputFields] = useState([]);
    // const AddAuthVars = () => {
    //     setAuthInputFields([...authInputFields, ""]);
    // };
    // const handleAuthInputChange = (index, event) => {
    //     const values = [...authInputFields];
    //     values[index] = event.target.value;
    //     setAuthInputFields(values);
    // };
    // const handleDeleteInput = (index) => {
    //     const newArray = [...authInputFields.slice(0, index), ...authInputFields.slice(index + 1)];
    //     setAuthInputFields(newArray);
    // };

    //<<<<<<<<<<<<<<<<<<<<<-------------------Authentication content end------------


    // const [templType, setTemplType] = useState('Standard');
    // const handleTemplateType = (e) => {
    //     setTemplType(e.target.value);
    //     if (e.target.value === 'Dynamic') {
    //         setFormat('interactive');
    //     }
    // }

    // ----------------FROMAT FOR CAROUSEL---------------
    const [format, setFormat] = useState('interactive');
    const handleMesgFormat = (e) => {
        setFormat(e.target.value);
    }
    const [header, setHeader] = useState('none');
    const handleHeader = (e) => {
        // if the user chooses no header but after choosing any other header type
        if (e.target.value === 'none') {
            delete FormFields.header;
            setPreview();
        }
        setHeader(e.target.value);
    }

    //--------------------------->>>>>>>>>>>>>>>> CAROUSEL CARDS
    const [cards, setCards] = useState([]);
    const [showCreateCardModal, setShowCreateCardModal] = useState(false);
    const toggleCreateCardModal = () => {
        if (cards.length < 10) {
            setShowCreateCardModal(!showCreateCardModal);
        } else {
            alert('Out of Cards Limit.')
        }
    };
    const handleCreateCard = (formData) => {
        // console.log('formdata received ', formData);
        const newCard = {
            cardText: formData.card_body,
            ...(formData.header && formData.header.type === "image" && { image: formData.header.example }),
            ...(formData.header && formData.header.type === "video" && { video: formData.header.example }),
        };
        setCards([...cards, newCard]);
        // console.log('jaa', formData);
    };
    //CAROUSEL CARDS END <<<<<<<<<<<<<<<<<<<<----------------------------

    //HEADER variables
    const [headerVars, setHeaderVars] = useState([]);
    const AddHeaderVars = () => {
        setHeaderVars([...headerVars, ""]);
    };
    const handleVarChange = (index, event) => {
        const values = [...headerVars];
        values[index] = event.target.value;
        setHeaderVars(values);
    };
    const handleDeleteVars = (index) => {
        const newArray = [...headerVars.slice(0, index), ...headerVars.slice(index + 1)];
        setHeaderVars(newArray);
    };

    //body variables
    const [inputFields, setInputFields] = useState([]);
    const AddVars = () => {
        setInputFields([...inputFields, ""]);
    };
    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        values[index] = event.target.value;
        setInputFields(values);
    };
    const handleDeleteBodyInput = (index) => {
        const newArray = [...inputFields.slice(0, index), ...inputFields.slice(index + 1)];
        setInputFields(newArray);
    };

    //Buttons
    const [textButtons, setTextButtons] = useState([]);
    const [urlButtons, setUrlButtons] = useState([]);
    const [phoneButtons, setPhoneButtons] = useState([]);
    const [formButtons, setFormButtons] = useState([]);

    // handle button counts
    const ChooseBtnType = (type) => {
        switch (type) {
            case 'text': {
                if (textButtons.length < 10) {
                    setTextButtons(prev => [...prev, { id: Date.now() }]);
                }
                break;
            }
            case 'url': {
                if (urlButtons.length < 2) {
                    setUrlButtons(prev => [...prev, { id: Date.now(), type: "dynamic" }]);
                }
                break;
            }
            case 'phone': {
                if (phoneButtons.length < 1) {
                    setPhoneButtons(prev => [...prev, { id: Date.now() }]);
                }
                break;
            }
            case 'form': {
                if (formButtons.length < 1) {
                    setFormButtons(prev => [...prev, { id: Date.now(), formName: '' }]);
                }
                break;
            }
            default: {
                break;
            }
        }
    };
    // handle inputs
    const handleFormChange = (type, id, field, value) => {
        switch (type) {
            case 'text':
                setTextButtons(prev => prev.map(btn => btn.id === id ? { ...btn, [field]: value } : btn));
                break;
            case 'url':
                setUrlButtons(prev => prev.map(btn => btn.id === id ? { ...btn, [field]: value } : btn));
                break;
            case 'phone':
                setPhoneButtons(prev => prev.map(btn => btn.id === id ? { ...btn, [field]: value } : btn));
                break;
            case 'form':
                setFormButtons(prev => prev.map(btn => btn.id === id ? { ...btn, formName: value } : btn));
                break;
            default:
                break;
        }
    };
    // delete buttons
    const deleteButton = (type, id) => {
        switch (type) {
            case 'text':
                setTextButtons(prev => prev.filter(btn => btn.id !== id));
                break;
            case 'url':
                setUrlButtons(prev => prev.filter(btn => btn.id !== id));
                break;
            case 'phone':
                setPhoneButtons(prev => prev.filter(btn => btn.id !== id));
                break;
            case 'form':
                setFormButtons(prev => prev.filter(btn => btn.id !== id));
                break;
            default:
                break;
        }
    };
    const buttonTypes = [
        { buttons: textButtons, content: (button) => <span className="btn-txt">{button.text}</span> },
        {
            buttons: urlButtons, content: (button) => <>
                <PiArrowSquareUpRight /><span className="btn-txt">{button.label}</span>
            </>
        },
        {
            buttons: phoneButtons, content: (button) => <>
                <PiPhone /><span className="btn-txt">{button.label}</span>
            </>
        }
    ];

    // GO TO FORM MODAL
    const [showCreateFormModal, setShowCreateFormModal] = useState(false);
    const toggleCreateFormModal = () => {
        setShowCreateFormModal(!showCreateFormModal);
    };

    //handle values + HEADER types
    const [FormFields, setFormFields] = useState({});
    const [preview, setPreview] = useState();
    const handleform2 = (e) => {
        if (e.target.name === 'doc' || e.target.name === 'image' || e.target.name === 'video') {
            setFormFields({
                ...FormFields,
                header: {
                    type: e.target.name,  // Dynamically set the type based on the input name (file, image, video)
                    example: e.target.value
                },
            });
            setPreview(e.target.preview);

        } else if (e.target.name === 'header') {
            setFormFields({
                ...FormFields,
                header: {
                    type: "text",
                    text: e.target.value,
                },
            });
        } else {
            setFormFields({
                ...FormFields,
                [e.target.name]: e.target.value,
            });
        }
    };


    const [validated, setValidated] = useState(false);
    const submitWhatsapp = (e) => {
        e.preventDefault();
        // Prevent the default form submission
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }

        // Handle buttons
        let allButtonData = [...textButtons,
        ...urlButtons,
        ...phoneButtons];
        const keyToRemove = 'id';
        const updatedArray = allButtonData.map(obj => {
            const { [keyToRemove]: _, ...rest } = obj;
            return rest;
        });

        let buttons = {};
        if (textButtons.length > 0 && (urlButtons.length > 0 || phoneButtons.length > 0)) {
            buttons = {
                type: "combined_buttons",
                elements: updatedArray
            };
        } else if (urlButtons.length > 0 || phoneButtons.length > 0) {
            buttons = {
                type: "call_to_action",
                elements: updatedArray
            };
        } else if (textButtons.length > 0) {
            buttons = {
                type: "quick_reply",
                elements: updatedArray
            };
        }
        // header
        let header = {};
        if (FormFields.header && FormFields.header.type === "text" && headerVars.length > 0) {
            header = {
                ...FormFields.header,
                example: headerVars
            }
        } else {
            header = FormFields.header
        }

        setValidated(true);
        if ((category === 'Marketing' || category === '') && (format === 'interactive')) {

            const postdata = {
                template_name: FormFields.template_name,
                template_language: ["en"],
                template_type: "text_template",
                template_category: "MARKETING",
                header: header,
                body: {
                    text: FormFields.template_content,
                    example: inputFields
                },
                footer: {
                    text: FormFields.footer
                },
                buttons: buttons
            };

            handleCreateWhatsapp(postdata);
        }
        else if ((category === 'Marketing' || category === '') && (format === 'carousel')) {

            const postdata = {
                template_name: FormFields.template_name,
                template_language: ["en"],
                template_type: "template",
                template_category: "MARKETING",
                header: header,
                body: {
                    text: FormFields.template_content,
                    example: inputFields
                },
            };

            handleCreateWhatsapp(postdata);
        }
        else if ((category === 'Utility')) {

            const postdata = {
                template_name: FormFields.template_name,
                template_language: ["en"],
                template_type: "text_template",
                template_category: "UTILITY",
                header: header,
                body: {
                    text: FormFields.template_content,
                    example: inputFields
                },
                footer: {
                    text: FormFields.footer
                },
                buttons: buttons
            };
            console.log('post data in utility', postdata);

            handleCreateWhatsapp(postdata);
        }
        else if (category === 'Authentication') {

            const postdata = {
                template_name: FormFields.template_name,
                template_language: ["en"],
                template_type: "template",
                template_category: "AUTHENTICATION",
                component: {
                    body: {
                        add_security_recommendation: disclaimer
                    },
                    footer: {
                        code_expiration_minutes: authValues.warning_expiryTime
                    },
                    buttons: {
                        type: "OTP",
                        otp_type: "COPY_CODE",
                        text: authValues.disclaimer_otp
                    }
                }
            }
            console.log('post data in auth', postdata);
            handleCreateWhatsapp(postdata);
        };
    };

    const [createWhatsapp] = useCreateWhatsappMarketingMutation();
    const handleCreateWhatsapp = async (postdata) => {
        try {
            setShowModal(true);
            const success = await createWhatsapp(postdata);

            if (success?.data?.statusCode === 200) {
                setReason(success?.data?.message);
                setVariant('tertiary');
                setProgress(100);
            } else if (success?.data?.statusCode > 300) {
                setReason(success?.data?.data?.message || 'Upload failed!');
                setVariant('warning');
                setProgress(100);
            }
        } catch (error) {
            setReason('Upload failed' || error);
            setVariant('warning');
            setShowModal(true);
            setProgress(100);
        }
    };



    //preview
    const date = moment();
    const currentTime = date.format("h:mm A");
    const highlightAndReplaceHeader = (content) => {
        // Regex to match any {{digit}} pattern
        const parts = content.split(/(\{\{\d+\}\})/);

        return parts.map((part, index) => {
            if (/\{\{\d+\}\}/.test(part)) {
                // Extract the number inside {{ }}
                const number = parseInt(part.match(/\d+/)[0], 10); // Get the digit inside {{ }}

                // Get the replacement value from valuesArray (adjusting for 0-based index)
                const replacementValue = headerVars[number - 1] || part; // Fallback to original if not found

                return (
                    <span key={index} style={{ color: 'blue' }}>
                        {replacementValue}
                    </span>
                );
            }
            return part; // Plain text part
        });
    };
    const highlightAndReplaceContent = (content) => {
        if (typeof content !== 'string') {
            return content;
        }

        // Regex to match any {{digit}} pattern
        const parts = content.split(/(\{\{\d+\}\})/);

        return parts.map((part, index) => {
            if (/\{\{\d+\}\}/.test(part)) {
                // Extract the number inside {{ }}
                const number = parseInt(part.match(/\d+/)[0], 10); // Get the digit inside {{ }}

                // Get the replacement value from valuesArray (adjusting for 0-based index)
                const replacementValue = inputFields[number - 1] || part; // Fallback to original if not found

                return (
                    <span key={index} style={{ color: 'blue' }}>
                        {replacementValue}
                    </span>
                );
            }
            return part; // Plain text part
        });
    };

    return (
        <Fragment>

            <Row>
                <Col xs={8} lg={8}>
                    <Form noValidate validated={validated} ref={formRef} onSubmit={submitWhatsapp}>
                        <div className="card p-3">
                            <Row>
                                <Form.Group as={Col} className="mb-3 withChars">
                                    <Form.Label>Template Name</Form.Label>
                                    <Form.Control required type="text" placeholder="abc" pattern="^[a-z0-9_]+$" maxLength={100} name="template_name" onChange={handleform2} />
                                    <p className="chars_count">{FormFields.template_name === undefined ? "0" : FormFields.template_name.length}/100</p>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid template name (only lowercase letters/digits and underscores allowed).
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} className="mb-3">
                                    <Form.Label>Category</Form.Label>
                                    <Form.Select name="category" onChange={handleCategory}>
                                        <option defaultValue>Marketing</option>
                                        <option>Utility</option>
                                        <option>Authentication</option>
                                    </Form.Select>

                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} className="mb-3">
                                    <Form.Label>Language</Form.Label>
                                    <Form.Select disabled>
                                        <option defaultValue>English</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* {category !== 'Authentication' && <Form.Group as={Col}>
                            <Form.Label>Template Type</Form.Label>
                            <Form.Select name="template_type" onChange={handleTemplateType}>
                                <option defaultValue>Standard</option>
                                <option>Dynamic</option>
                            </Form.Select>
                        </Form.Group>} */}
                                {category !== 'Authentication' && category !== 'Utility' && <Form.Group as={Col} className="mb-3">
                                    <Form.Label>Sub-Category</Form.Label>
                                    <Form.Select name="template_type" onChange={handleMesgFormat}>
                                        <option defaultValue value="interactive">Interactive</option>
                                        <option value="carousel">Carousel</option>
                                    </Form.Select>
                                </Form.Group>}
                            </Row>

                            {/* {category !== 'Authentication' && <Row className="">
                        <div className="d-flex">
                            <div style={{ backgroundColor: '#E5E7EB', borderRadius: '10px' }}>
                                <Button onClick={handleMesgFormat} variant={format === 'interactive' ? "secondary" : "white"} value={"interactive"} className="m-2"><IoIosPhonePortrait /> Interactive</Button>
                                <Button disabled={templType === 'Dynamic'} onClick={handleMesgFormat} variant={format === 'carousel' ? "secondary" : "white"} value={"carousel"} className="m-2"><TbCarouselHorizontal /> Carousel</Button>
                            </div>
                        </div>
                    </Row>} */}
                        </div>


                        {format !== 'carousel' && category !== 'Authentication' && <div className="card light p-3 mt-3">
                            <Row>
                                <Form.Group as={Col} className="mb-3">
                                    <Form.Label>Header (Optional)</Form.Label>
                                    <div className="d-flex flex-wrap">
                                        <Button onClick={handleHeader} variant={header === 'none' ? "success" : "white"} value={"none"} className="m-2"><MdOutlineDoNotDisturbAlt /> None</Button>
                                        <Button onClick={handleHeader} variant={header === 'text' ? "success" : "white"} value={"text"} className="m-2"><MdTextFields /> Text</Button>
                                        <Button onClick={handleHeader} variant={header === 'image' ? "success" : "white"} value={"image"} className="m-2"><ImImages />  Image</Button>
                                        <Button onClick={handleHeader} variant={header === 'video' ? "success" : "white"} value={"video"} className="m-2"><MdOutlineVideocam /> Video</Button>
                                        <Button onClick={handleHeader} variant={header === 'doc' ? "success" : "white"} value={"doc"} className="m-2"><IoDocumentTextOutline /> Document</Button>
                                    </div>
                                </Form.Group>
                            </Row>
                            {header === 'text' && <Row className="mb-3">
                                <Form.Group as={Col} className="withChars">
                                    <div className="d-flex justify-content-between">
                                        <Form.Label>Header</Form.Label>
                                        <Button onClick={AddHeaderVars} bsPrefix="text" style={{ border: "none", background: "none", padding: 0, color: "inherit" }} variant="primary" className="d-flex align-items-center text-success fw-bold">
                                            <FcPlus className="icon icon-xs me-2" />Add Variable
                                        </Button>
                                    </div>
                                    <Form.Control type="text" required placeholder="provide header" maxLength={60} name="header" onChange={handleform2} />
                                    <p className="chars_count ta">{FormFields.header === undefined ? "0" : (FormFields.header.text).length}/60</p>
                                </Form.Group>
                                <Row>
                                    {headerVars.map((input, index) => (
                                        <Form.Group as={Col} xs={6} md={4} key={index} className="mt-3">
                                            <Form.Label className="text-success">{`Var {{${index + 1}}}`}</Form.Label>
                                            <div className="d-flex align-items-center">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Value"
                                                    value={input}
                                                    onChange={(event) => handleVarChange(index, event)}
                                                    name="template_variables"
                                                />
                                                <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                                                    <Card.Link className="ms-2" onClick={() => handleDeleteVars(index)}>
                                                        <XCircleIcon className="icon icon-xs text-danger" />
                                                    </Card.Link>
                                                </OverlayTrigger>
                                            </div>
                                        </Form.Group>
                                    ))}
                                </Row>
                            </Row>}
                            {header === 'image' && <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <DropImagesForm onFilesUploaded={handleform2} />
                                </Form.Group>
                            </Row>}
                            {header === 'video' && <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <DropVideosForm onFilesUploaded={handleform2} />
                                </Form.Group>
                            </Row>}
                            {header === 'doc' && <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <DropMediaFilesForm onFilesUploaded={handleform2} />
                                </Form.Group>
                            </Row>}
                        </div>}

                        {format === 'carousel' && category !== 'Authentication' && category !== 'Utility' &&
                            <div className="card light p-3 mt-3">
                                <div className="cards-container">
                                    <div className="create-card-wa" onClick={toggleCreateCardModal}>
                                        <div style={{ textAlign: 'center' }}>
                                            <PlusIcon className="icon icon-xs mb-2" />
                                            <p className="px-4">Create a Custom Card</p>
                                            <p className="px-4">{`(${cards.length}/10)`}</p>
                                        </div>
                                    </div>
                                    <div className="cards-slider">
                                        {!showCreateCardModal && cards.map((card, index) => (
                                            <div key={index} className="card-wa">
                                                <span className="d-flex w-100">
                                                    <p className="h6 card-text text-success">{`Card ${index + 1}`}</p>
                                                    <Dropdown className="ms-auto">
                                                        <Dropdown.Toggle variant="link" className="text-dark m-0 p-0">
                                                            <DotsVerticalIcon className="icon icon-xs icon-dark" />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="py-0">
                                                            <Dropdown.Item>
                                                                <PencilIcon className="icon icon-xs me-2 text-primary" /> Edit
                                                            </Dropdown.Item>
                                                            <Dropdown.Item >
                                                                <XIcon className="icon icon-xs me-2 text-danger" /> Delete
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </span>
                                                {card.image && <img src={card.image} alt="uploaded" className="imageStyle" />}
                                                {card.video && <video src={card.video} alt="uploaded" className="imageStyle" />}
                                                <p className="small m-0">{card.cardText.length > 30 ? card.cardText.slice(0, 30) + "..." : card.cardText}</p>
                                            </div>
                                        ))}
                                    </div>

                                    {showCreateCardModal && (
                                        <CreateCards
                                            show={showCreateCardModal}
                                            onHide={toggleCreateCardModal}
                                            onSubmit={handleCreateCard}
                                            index={cards.length}
                                        />
                                    )}
                                </div>
                            </div>
                        }


                        {category === 'Authentication' &&
                            <div className="card p-3 mt-3">
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>Content</Form.Label>
                                        <Form.Check
                                            type="switch"
                                            label="Security Disclaimer"
                                            id="switch1"
                                            htmlFor="switch1"
                                            name="disclaimer"
                                            onChange={handleAuthContent}
                                        />
                                        <Form.Check
                                            type="switch"
                                            label="Expiration Warning"
                                            id="switch2"
                                            htmlFor="switch2"
                                            name="warning"
                                            onChange={handleAuthContent}
                                        />
                                        {authContent.warning && <div className="d-flex align-items-center">
                                            <p className="m-0">Expiry Time:</p>
                                            <Form.Control
                                                required
                                                className="mx-2 w-25"
                                                type="text"
                                                placeholder="0-90"
                                                name="warning_expiryTime"
                                                value={authValues.warning_expiryTime}
                                                onChange={handleAuthValues}
                                            />
                                            <p className="m-0">min</p>
                                        </div>}
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>Header (Optional)</Form.Label>
                                        <div className="d-flex flex-wrap">
                                            <Button variant="success" value={"none"}><MdOutlineDoNotDisturbAlt /> None</Button>
                                        </div>
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3">
                                    {/* <div className="d-flex justify-content-between">
                                    <Form.Label>Content Body</Form.Label>
                                    <Button onClick={AddAuthVars} bsPrefix="text" style={{ border: "none", background: "none", padding: 0, color: "inherit" }} variant="primary" className="d-flex align-items-center text-success fw-bold">
                                        <FcPlus className="icon icon-xs me-2" />Add Variable
                                    </Button>
                                </div> */}
                                    <Form.Control as="textarea" rows="5" disabled value={disclaimer ? `${authValues.disclaimer_otp || '{{1}}'} is your verification code. For Your Security, do not share this code.` : '-- Set Disclaimer to show content --'} />
                                    {/* <Form.Control.Feedback type="invalid">
                                    Please provide a valid Message Content.
                                </Form.Control.Feedback> */}
                                </Form.Group>

                                {/* <Row className="mb-3">
                                {authInputFields.map((input, index) => (
                                    <Form.Group as={Col} key={index}>
                                        <Form.Label>Var {index + 1}</Form.Label>
                                        <div className="d-flex align-items-center">
                                            <Form.Control
                                                type="text"
                                                placeholder="Value"
                                                value={input}
                                                onChange={(event) => handleAuthInputChange(index, event)}
                                                name="auth_variables"
                                            />
                                            <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                                                <Card.Link className="ms-2" onClick={() => handleDeleteInput(index)}>
                                                    <XCircleIcon className="icon icon-xs text-danger" />
                                                </Card.Link>
                                            </OverlayTrigger>
                                        </div>
                                    </Form.Group>
                                ))}
                            </Row> */}

                                {authContent.warning && <Form.Group className="mb-3">
                                    <Form.Label>Footer</Form.Label>
                                    <Form.Control type="text" disabled name="footer" value={`This code expires in ${authValues.warning_expiryTime} minutes.`} />
                                </Form.Group>}

                                {authContent.disclaimer && <Form.Group className="mb-3">
                                    <Form.Label>Button Text</Form.Label>
                                    <p className="fw-light m-0"><MdOutlineContentCopy /> Copy Code</p>
                                    <Form.Control type="text" className="w-25" required placeholder="OTP" name="disclaimer_otp" onChange={handleAuthValues} value={authValues.disclaimer_otp} />
                                </Form.Group>}
                            </div>
                        }

                        {category !== 'Authentication' && <div className="card p-3 mt-3">
                            <Form.Group className="mb-3 withChars">
                                <div className="d-flex justify-content-between">
                                    <Form.Label>Content Body</Form.Label>
                                    <Button onClick={AddVars} bsPrefix="text" style={{ border: "none", background: "none", padding: 0, color: "inherit" }} variant="primary" className="d-flex align-items-center text-success fw-bold">
                                        <FcPlus className="icon icon-xs me-2" />Add Variable
                                    </Button>
                                </div>
                                <Form.Control as="textarea" rows="7" required placeholder="Enter Message Content with variables as {{1}}." name="template_content" maxLength={1032} onChange={handleform2} />
                                <p className="chars_count ta">{FormFields.template_content === undefined ? "0" : FormFields.template_content.length}/1032</p>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a Message Content.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Row className="mb-3">
                                {inputFields.map((input, index) => (
                                    <Form.Group as={Col} xs={6} md={4} key={index}>
                                        <Form.Label className="text-success">{`Var {{${index + 1}}}`}</Form.Label>
                                        <div className="d-flex align-items-center">
                                            <Form.Control
                                                type="text"
                                                placeholder="Value"
                                                value={input}
                                                onChange={(event) => handleInputChange(index, event)}
                                                name="template_variables"
                                            />
                                            <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                                                <Card.Link className="ms-2" onClick={() => handleDeleteBodyInput(index)}>
                                                    <XCircleIcon className="icon icon-xs text-danger" />
                                                </Card.Link>
                                            </OverlayTrigger>
                                        </div>
                                    </Form.Group>
                                ))}
                            </Row>

                            {format === 'interactive' && category !== 'Authentication' && <Form.Group className="mb-3 withChars">
                                <Form.Label>Footer</Form.Label>
                                <Form.Control type="text" required placeholder="footer" maxLength={60} name="footer" onChange={handleform2} />
                                <p className="chars_count ta">{FormFields.footer === undefined ? "0" : FormFields.footer.length}/60</p>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a Footer.
                                </Form.Control.Feedback>
                            </Form.Group>}
                        </div>}

                        {category !== 'Authentication' && <div className="card light p-3 mt-3">
                            <Row className="pb-3">
                                <Form.Group as={Col}>
                                    <Form.Label htmlFor="basic-url">Button (Optional)</Form.Label>
                                </Form.Group>
                                <Dropdown as={Col} className="d-flex">
                                    <Dropdown.Toggle as={Button} variant="white" className="d-inline-flex alignpitems-center arrow-none ms-auto">
                                        Add Buttons<ChevronDownIcon className="icon icon-xs ms-1" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
                                        <Dropdown.Item className={formButtons.length > 0 && textButtons.length < 10 ? "d-flex align-items-center hover-info notAllowed" : "d-flex align-items-center hover-info"} onClick={() => ChooseBtnType('text')}>
                                            Quick Reply: {textButtons.length}/10
                                        </Dropdown.Item>
                                        <Dropdown.Divider as="div" className="my-1" />
                                        <Dropdown.Item as="div" className="d-flex align-items-start flex-column nestedDropdown">
                                            Call-to-action
                                            <ul className="list-unstyled ms-2 mt-2 mb-0">
                                                <li onClick={() => formButtons.length === 0 && urlButtons.length < 2 && ChooseBtnType('url')}>
                                                    <p className={formButtons.length > 0 || urlButtons.length >= 2 ? "dropdown-link notAllowed" : "dropdown-link"}>
                                                        Visit Website: {urlButtons.length}/2
                                                    </p>
                                                </li>
                                                <li onClick={() => formButtons.length === 0 && phoneButtons.length < 1 && ChooseBtnType('phone')}>
                                                    <p className={formButtons.length > 0 || phoneButtons.length >= 1 ? "dropdown-link notAllowed" : "dropdown-link"}>
                                                        Call Phone Number: {phoneButtons.length}/1
                                                    </p>
                                                </li>
                                                <li onClick={() => textButtons.length === 0 && urlButtons.length === 0 && phoneButtons.length === 0 && formButtons.length < 1 && ChooseBtnType('form')}>
                                                    <p className={textButtons.length > 0 || urlButtons.length > 0 || phoneButtons.length > 0 || formButtons.length === 1 ? 'dropdown-link notAllowed' : 'dropdown-link'}>
                                                        Complete Form: {formButtons.length}/1
                                                    </p>
                                                </li>
                                            </ul>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Row>

                            <div className="card p-2">
                                <Row>
                                    <Form.Label className="border-bottom pb-2 text-success fw-bolder">Quick Reply</Form.Label>
                                    {textButtons.map((button, index) => (
                                        <Form.Group key={button.id} as={Col} lg={9} className="d-flex align-items-center my-2">
                                            <Form.Control
                                                type="text"
                                                required
                                                placeholder={`Button ${index + 1}`}
                                                onChange={(e) => handleFormChange('text', button.id, 'text', e.target.value)}
                                            />
                                            <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                                                <Card.Link className="ms-2" onClick={() => deleteButton('text', button.id)}>
                                                    <XCircleIcon className="icon icon-xs text-danger" />
                                                </Card.Link>
                                            </OverlayTrigger>
                                        </Form.Group>
                                    ))}
                                </Row>
                            </div>

                            <div className="card p-2 mt-2">
                                <Row>
                                    <Form.Label className="border-bottom pb-2 text-success fw-bolder">Call-to-action</Form.Label>
                                    {urlButtons.map((button, index) => (
                                        <div key={button.id} className="mb-3">
                                            <Form.Label>URL</Form.Label>
                                            <Row>
                                                <Col md={11}>
                                                    <Row>
                                                        <Col md={2} className="pe-0">
                                                            <Form.Select onChange={(e) => handleFormChange('url', button.id, 'type', e.target.value)}>
                                                                <option>Static</option>
                                                                <option>Dynamic</option>
                                                            </Form.Select>
                                                        </Col>
                                                        <Col md={4} className="pe-0">
                                                            <Form.Control
                                                                type="text"
                                                                required
                                                                placeholder={`Button text ${index + 1}`}
                                                                onChange={(e) => handleFormChange('url', button.id, 'label', e.target.value)}
                                                            />
                                                        </Col>
                                                        {button.type !== 'Dynamic' ? <Col md={6} className="pe-0">
                                                            <Form.Control
                                                                type="url"
                                                                required
                                                                placeholder="https://example.com"
                                                                onChange={(e) => handleFormChange('url', button.id, 'website', e.target.value)}
                                                            />
                                                        </Col> :
                                                            <Col md={6} className="pe-0">
                                                                <Form.Control
                                                                    type="url"
                                                                    required
                                                                    className="mb-2"
                                                                    placeholder="https://www.example.com/{{1}}"
                                                                    onChange={(e) => handleFormChange('url', button.id, 'website', e.target.value)}
                                                                />
                                                                <Form.Control
                                                                    type="url"
                                                                    required
                                                                    placeholder="example url"
                                                                    onChange={(e) => handleFormChange('url', button.id, 'website_example', e.target.value)}
                                                                />
                                                            </Col>}
                                                    </Row>
                                                </Col>
                                                <Col md={1} className="d-flex align-items-center">
                                                    <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                                                        <Card.Link onClick={() => deleteButton('url', button.id)}>
                                                            <XCircleIcon className="icon icon-xs text-danger" />
                                                        </Card.Link>
                                                    </OverlayTrigger>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                    {phoneButtons.map((button) => (
                                        <div key={button.id} className="mb-3">
                                            <Form.Label>Phone Number</Form.Label>
                                            <Row>
                                                <Col md={11}>
                                                    <Row>
                                                        <Col md={5} className="pe-0">
                                                            <Form.Control
                                                                type="text"
                                                                required
                                                                placeholder="Button text"
                                                                onChange={(e) => handleFormChange('phone', button.id, 'label', e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col md={7} className="pe-0">
                                                            <PhoneInput
                                                                country={"in"}
                                                                required
                                                                onChange={(num) => handleFormChange('phone', button.id, 'contact_no', `+${num}`)}
                                                                placeholder="+91 99999-99999"
                                                                className="phone-input"
                                                                autoComplete="new-phone"
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={1} className="d-flex align-items-center">
                                                    <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                                                        <Card.Link onClick={() => deleteButton('phone', button.id)}>
                                                            <XCircleIcon className="icon icon-xs text-danger" />
                                                        </Card.Link>
                                                    </OverlayTrigger>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                    {formButtons.map((button) => (
                                        <div key={button.id}>
                                            <Form.Label>Complete Form</Form.Label>
                                            <Row>
                                                <Col md={11}>
                                                    <Row>
                                                        <Col md={8} className="pe-0">
                                                            <Form.Control
                                                                type="text"
                                                                required
                                                                placeholder="Form Name"
                                                                onChange={(e) => handleFormChange('form', button.id, 'formName', e.target.value)}
                                                            />
                                                        </Col>
                                                        <Col md={4} className="pe-0">
                                                            <Button variant="outline-success rounded-pill" onClick={toggleCreateFormModal}>Create Form</Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={1} className="d-flex align-items-center">
                                                    <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                                                        <Card.Link onClick={() => deleteButton('form', button.id)}>
                                                            <XCircleIcon className="icon icon-xs text-danger" />
                                                        </Card.Link>
                                                    </OverlayTrigger>
                                                </Col>
                                            </Row>


                                            {showCreateFormModal && (
                                                <CreateForm
                                                    show={showCreateFormModal}
                                                    onHide={toggleCreateFormModal}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </Row>
                            </div>
                        </div>}

                        <div className="d-flex mt-3">
                            {/* <Button variant="gray-800" className="animate-up-2 me-2">
                                Send Approval
                            </Button> */}
                            <Button variant="gray-800" type="submit" className="animate-up-2">
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Col>
                <Col xs={4} lg={4}>
                    <div className="img-cont">
                        <img src={whatsappPic} alt="whatsapp preview" />
                        <div className="preview-cont">

                            {(FormFields?.header || FormFields.template_content || FormFields?.footer) &&
                                <div className="whatsBody">
                                    {FormFields?.header?.type === "text" && <span className="w-header">{highlightAndReplaceHeader(FormFields?.header?.text)}</span>}
                                    {FormFields?.header?.type === "image" && <Image src={preview} className="w-image" />}
                                    {FormFields?.header?.type === "video" &&
                                        <video controls width="100%" className="w-image">
                                            <source src={preview} type="video/mp4" />
                                            Unsupported video tag by browser.
                                        </video>}
                                    {FormFields?.header?.type === "doc" &&
                                        <span className="w-doc">
                                            {preview === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? <RiFileWord2Fill style={{ color: '#2A5293' }} />
                                                : preview === 'application/pdf' ? <AiFillFilePdf style={{ color: '#CB3724' }} />
                                                    : preview === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? <SiMicrosoftexcel style={{ color: '#4AA66A' }} />
                                                        : <GrDocumentText />}

                                        </span>
                                    }

                                    <span className="w-body">{highlightAndReplaceContent(FormFields?.template_content)}</span>
                                    <br />
                                    {FormFields.footer && <span className="w-footer">{FormFields?.footer}</span>}
                                    <div className="time_box">
                                        <span className="ms-auto">{currentTime}</span>
                                    </div>

                                    {buttonTypes.some(({ buttons }) => buttons.length > 0) && (
                                        <>
                                            <div className="wdivider" />
                                            {buttonTypes.map(({ buttons, content }, typeIndex) => (
                                                buttons.map((button, buttonIndex) => (
                                                    <div className="whatsButton" key={button.id || `${typeIndex}-${buttonIndex}`}>
                                                        {content(button)}
                                                    </div>
                                                ))
                                            ))}
                                        </>
                                    )}

                                </div>
                            }
                        </div>
                    </div>
                </Col>
            </Row>



            <Modal
                centered
                show={showModal}
                onHide={handleCloseModal}
                className={`modal-${variant}`}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header className="mx-auto">
                    <p className="lead mb-0 text-white">Creating WhatsApp Template</p>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <div className="py-3 px-5 text-center">
                        {progress !== 100 ?
                            (<span className="modal-icon display-1">
                                <ClockIcon className="icon icon-xl text-white" />
                            </span>) :
                            variant === 'tertiary' ?
                                (<span className="modal-icon display-1">
                                    <CheckCircleIcon className="icon icon-xl text-white" />
                                </span>) :
                                <span className="modal-icon display-1">
                                    <ShieldExclamationIcon className="icon icon-xl text-white" />
                                </span>
                        }

                        <Modal.Title className="text-white mb-3">
                            {progress !== 100 ? <h3>Sending Request</h3> : variant === 'tertiary' ? <h3>Template Created Successfully</h3> : <h3>Something Went Wrong</h3>}
                        </Modal.Title>
                        <ProgressBar variant="primary" now={progress} min={0} max={100} animated />
                        {variant !== 'tertiary' ?
                            <p className="mb-4 text-danger">
                                {reason}
                            </p> : ''}
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center pt-0 pb-3">
                    <Button variant="white" size="sm" className="text-primary" onClick={handleRefresh}>
                        OK
                    </Button>
                    {variant !== 'tertiary' && <Button variant="white" size="sm" className="text-primary fw-bold" onClick={handleCloseModal}>
                        Retry
                    </Button>}
                </Modal.Footer>
            </Modal>
        </Fragment>

    )
}