export const CampaignCountDetails = (builder) => ({
    CampaignCountDetails: builder.mutation({
      query: ({ userId, category }) => {
        const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';  
        return {
          url: '/campign_counts',
          method: 'GET',
          params: { userId, category },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          },
        };
      },
    }),
  });