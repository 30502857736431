
import React, { useState } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "routes";
import { jwtDecode } from 'jwt-decode';

// pages
import Presentation from "pages/Presentation";
import DashboardOverview from "pages/dashboard/DashboardOverview";
import DashboardTraffic from "pages/dashboard/DashboardTraffic";
import DashboardProductAnalysis from "pages/dashboard/DashboardProductAnalysis";
import Kanban from 'pages/Kanban';
import Messages from "pages/Messages";
import SingleMessage from "pages/SingleMessage";
import Users from "pages/Users";
import Transactions from "pages/Transactions";
import Tasks from "pages/Tasks";
import Settings from "pages/Settings";
import Calendar from "pages/Calendar";
import MapPage from "pages/Map";
import Datatables from "pages/tables/Datatables";
import BootstrapTables from "pages/tables/BootstrapTables";
import Pricing from "pages/examples/Pricing";
import Billing from "pages/examples/Billing";
import Invoice from "pages/examples/Invoice";
import Signin from "pages/examples/Signin";
import Signup from "pages/examples/Signup";
import ForgotPassword from "pages/examples/ForgotPassword";
import ResetPassword from "pages/examples/ResetPassword";
import Lock from "pages/examples/Lock";
import Widgets from "pages/examples/Widgets";
import NotFoundPage from "pages/examples/NotFound";
import ServerError from "pages/examples/ServerError";

// documentation pages
import DocsOverview from "pages/documentation/DocsOverview";
import DocsDownload from "pages/documentation/DocsDownload";
import DocsQuickStart from "pages/documentation/DocsQuickStart";
import DocsLicense from "pages/documentation/DocsLicense";
import DocsFolderStructure from "pages/documentation/DocsFolderStructure";
import DocsBuild from "pages/documentation/DocsBuild";
import DocsChangelog from "pages/documentation/DocsChangelog";

// plugin pages
import PluginCharts from "pages/plugins/Charts";
import PluginCalendar from "pages/plugins/Calendar";
import PluginDatatable from "pages/plugins/Datatable";
// import PluginMap from "pages/plugins/Map";
import PluginDropzone from "pages/plugins/Dropzone";
import PluginSweetAlert from "pages/plugins/SweetAlert";

// components
import Sidebar from 'components/Sidebar';
import Topbar from 'components/Topbar';
import Footer from 'components/Footer';

import Accordion from "pages/components/Accordion";
import Alerts from "pages/components/Alerts";
import Badges from "pages/components/Badges";
import Breadcrumbs from "pages/components/Breadcrumbs";
import Buttons from "pages/components/Buttons";
import Forms from "pages/components/Forms";
import Modals from "pages/components/Modals";
import Navs from "pages/components/Navs";
import Navbars from "pages/components/Navbars";
import Pagination from "pages/components/Pagination";
import Popovers from "pages/components/Popovers";
import Progress from "pages/components/Progress";
import Tables from "pages/components/Tables";
import Tabs from "pages/components/Tabs";
import Tooltips from "pages/components/Tooltips";
import Toasts from "pages/components/Toasts";
import WidgetsComponent from "pages/components/Widgets";
import UserOnboarding from './userOnboarding/UserOnboarding';
import ContactList from './contactList/ContactList';
import Wallet from './wallet/Wallet'
import Template from './template/TemplateTables';
import CreateTemplate from './template/CreateTemplate';
import ChannelTesting from './channelTesting/ChannelTesting';
import campaignList from './campaigns/campaignList';
import campaignCreate from './campaigns/campaignCreate';
import routeManagement from './routeManagement/routeManagement';
import msgRate from './messageRate/msgRate';
import summary from './summary/campaignSum';
import viewCDR from './summary/viewCDR';
import downloadCentre from './summary/downloadCentre';

const RouteWithSidebar = ({ component: Component, allowedRoles, ...rest }) => {
  const resize = () => {
    var resize = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 10);
    setTimeout(function () {
      clearInterval(resize);
    }, 301);
  };

  const localStorageIsContracted = () => {
    return localStorage.getItem('sidebarContracted') === 'true';
  };

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') !== 'false';
  };

  const [contracted, setContracted] = useState(localStorageIsContracted());
  const [contractSidebar, setContractSidebar] = useState(localStorageIsContracted());
  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible());

  const toggleMouseOver = () => {
    if (contracted) {
      setContractSidebar(!contractSidebar);
    }
    resize();
  };

  const toggleContracted = () => {
    setContracted(!contracted);
    setContractSidebar(!contracted);
    localStorage.setItem('sidebarContracted', !contracted);
    resize();
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  };

  const getToken = () => {
    const storedTokenData = JSON.parse(localStorage.getItem('TOKEN'));
    return storedTokenData?.token;
  };

  const isTokenExpired = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      return decodedToken.exp < currentTime;
    } catch (e) {
      return true;
    }
  };
  const token = getToken();
  const userType = token ? jwtDecode(token).user_type : null;

  return (
    <Route
      {...rest}
      render={props => {
        localStorage.setItem('userType', userType);

        // If token is not present or expired, redirect to login page
        if (!token || isTokenExpired(token)) {
          localStorage.removeItem('TOKEN'); // Clear expired token from localStorage
          return <Redirect to="/" />;
        }
        if (!allowedRoles.includes(userType)) {
          return <Redirect to={Routes.NotFound.path} />;
        }

        return (
          <>
            <Sidebar
              contracted={contractSidebar}
              onMouseEnter={toggleMouseOver}
              onMouseLeave={toggleMouseOver}
            />
            <main className="content">
              <Topbar toggleContracted={toggleContracted} />
              <Component {...props} />
              <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
            </main>
          </>
        );
      }}
    />
  );
};

export default () => (
  <Switch>
    <Route exact path={Routes.Presentation.path} component={Presentation} />
    <Route exact path={Routes.Signin.path} component={Signin} />
    <Route exact path={Routes.Signup.path} component={Signup} />
    <Route exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <Route exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <Route exact path={Routes.Lock.path} component={Lock} />
    <Route exact path={Routes.NotFound.path} component={NotFoundPage} />
    <Route exact path={Routes.ServerError.path} component={ServerError} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.DashboardTraffic.path} component={DashboardTraffic} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.DashboardProductAnalysis.path} component={DashboardProductAnalysis} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Summary.path} component={summary} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.ViewCDR.path} component={viewCDR} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.DownloadCentre.path} component={downloadCentre} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise','admin']} />


    {/* super admin start */}
    <RouteWithSidebar exact path={Routes.UserOnboarding.path} component={UserOnboarding} allowedRoles={['super_admin', 'customer relationship manager', 'admin']} />
    <RouteWithSidebar exact path={Routes.Wallet.path} component={Wallet} allowedRoles={['super_admin', 'customer relationship manager', 'admin']} />
    <RouteWithSidebar exact path={Routes.ChannelTesting.path} component={ChannelTesting} allowedRoles={['super_admin', 'admin']} />
    {/* super admin end */}

    {/* enterprise start*/}
    <RouteWithSidebar exact path={Routes.ContactList.path} component={ContactList} allowedRoles={['enterprise']} />


    <RouteWithSidebar exact path={Routes.Template.path} component={Template} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.CreateTemplate.path} component={CreateTemplate} allowedRoles={['enterprise']} />

    <RouteWithSidebar exact path={Routes.Campaign.path} component={campaignList} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.CreateCampaign.path} component={campaignCreate} allowedRoles={['enterprise']} />

    <RouteWithSidebar exact path={Routes.RouteManagement.path} component={routeManagement} allowedRoles={['super_admin', 'admin']} />
    <RouteWithSidebar exact path={Routes.MessageRate.path} component={msgRate} allowedRoles={['super_admin', 'admin', 'customer relationship manager']} />
    {/* enterprise end*/}

    <RouteWithSidebar exact path={Routes.Kanban.path} component={Kanban} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Messages.path} component={Messages} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.SingleMessage.path} component={SingleMessage} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Users.path} component={Users} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Tasks.path} component={Tasks} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Calendar.path} component={Calendar} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Map.path} component={MapPage} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Datatables.path} component={Datatables} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.BootstrapTables.path} component={BootstrapTables} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Pricing.path} component={Pricing} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Billing2.path} component={Billing} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Invoice.path} component={Invoice} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />

    {/* components */}
    <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Widgets.path} component={Widgets} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.WidgetsComponent.path} component={WidgetsComponent} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />

    {/* documentation */}
    <RouteWithSidebar exact path={Routes.DocsOverview.path} component={DocsOverview} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.DocsDownload.path} component={DocsDownload} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.DocsQuickStart.path} component={DocsQuickStart} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.DocsLicense.path} component={DocsLicense} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.DocsFolderStructure.path} component={DocsFolderStructure} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.DocsBuild.path} component={DocsBuild} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.DocsChangelog.path} component={DocsChangelog} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />

    {/* plugins */}
    <RouteWithSidebar exact path={Routes.PluginCharts.path} component={PluginCharts} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.PluginCalendar.path} component={PluginCalendar} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.PluginDatatable.path} component={PluginDatatable} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    {/* <RouteWithSidebar exact path={Routes.PluginMap.path} component={PluginMap} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']}/> */}
    <RouteWithSidebar exact path={Routes.PluginDropzone.path} component={PluginDropzone} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />
    <RouteWithSidebar exact path={Routes.PluginSweetAlert.path} component={PluginSweetAlert} allowedRoles={['super_admin', 'customer relationship manager', 'enterprise', 'admin']} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
