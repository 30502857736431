export const GetAllUserSA = (builder) => ({
    GetAllUserSA: builder.mutation({
      query: () => {
        const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';  
        return {
          url: '/get_all_users',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          },
        };
      },
    }),
  });