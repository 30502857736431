export const CampaignFile = (builder) => ({
    CampaignFile: builder.mutation({
        query: (body) => {
            const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
            return {
                url: '/campaign_file', // Adjusted to match your curl endpoint
                method: 'POST',
                body: body, // Pass formData directly as the request body
                headers: {
                    'Authorization': token,
                },
            };
        },
    }),
});
