export const getMsgRatesList = (builder) => ({
    getMsgRatesList: builder.mutation({
      query: ({ comm_type, pageNumber, limit, user_type, search }) => {
        const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
  
        return {
          url: '/assigned_route',
          method: 'GET',
          params: { comm_type, pageNumber, limit, user_type, search },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          },
        };
      },
    }),
  });
  