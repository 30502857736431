
import React, { useState } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { CSSTransition } from 'react-transition-group';
import { ArchiveIcon, CalendarIcon, ChartBarIcon, ChartPieIcon, ChevronRightIcon, ClipboardListIcon, CogIcon, CreditCardIcon, InboxIcon, InformationCircleIcon, NewspaperIcon, TableIcon, TemplateIcon, UsersIcon, ViewGridIcon, XIcon } from "@heroicons/react/solid";
import { LogoutIcon } from "@heroicons/react/outline";
import { Nav, Badge, Image, Button, Dropdown, Navbar, Collapse, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "routes";
import ReactHero from "assets/img/technologies/react-hero-logo.svg";
import ProfilePicture from "assets/img/team/profile-picture-3.jpg";
import { FaMoneyBillTrendUp, FaWallet } from "react-icons/fa6";
import { MdCampaign } from "react-icons/md";
import { RiContactsBook3Fill } from "react-icons/ri";
import { MdOutlineSettingsInputAntenna } from "react-icons/md";
import { CiRoute } from "react-icons/ci";
import { BiLogOutCircle } from "react-icons/bi";
import logo from "../../src/assets/img/Telkosh.png";
import LogoIcon from "../assets/img/icons/icon.png";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from "react-router-dom";
import { ImInsertTemplate } from "react-icons/im";
import { TbLayoutDashboardFilled } from "react-icons/tb";


const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary me-3',
    cancelButton: 'btn btn-gray'
  },
  buttonsStyling: false
}));


export default (props = {}) => {

  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const [collapsedItems, setCollapsedItems] = useState([pathname]);
  const contracted = props.contracted ? "contracted" : "";
  const showClass = show ? "show" : "";


  const userType = localStorage.getItem('userType')

  const onCollapse = () => setShow(!show);
  const onMouseEnter = () => props.onMouseEnter && props.onMouseEnter();
  const onMouseLeave = () => props.onMouseLeave && props.onMouseLeave();

  const onNavItemCollapse = (itemKey) => {
    const isCollapsed = collapsedItems.some(item => item.includes(itemKey));
    const newCollapsedItems = isCollapsed ? collapsedItems.filter(item => !item.includes(itemKey)) : [...collapsedItems, itemKey];
    setCollapsedItems(newCollapsedItems);
  };

  const events = isMobile ? {} : { onMouseEnter, onMouseLeave };

  const Logout = async () => {

    const textMessage = "Are you sure you want to log out?";

    const result = await SwalWithBootstrapButtons.fire({
      icon: "question",
      title: "Confirm Exit",
      text: textMessage,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel"
    });

    if (result.isConfirmed) {
      history.push(Routes.Presentation.path);
    }
  }

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon: NavItemIcon, children = null } = props;
    const isOpened = collapsedItems.some(item => item.includes(eventKey));

    return (
      <Nav.Item>
        <Nav.Link
          onClick={() => onNavItemCollapse(eventKey)}
          aria-expanded={isOpened}
          aria-controls={eventKey}
          className="d-flex justify-content-between align-items-center"
        >
          <span>
            <span className="sidebar-icon">
              <NavItemIcon className="icon icon-xs me-2" />
            </span>
            <span className="sidebar-text">
              {title}
            </span>
          </span>
          <span className="link-arrow">
            <ChevronRightIcon className="icon icon-sm" />
          </span>
        </Nav.Link>
        <Collapse in={isOpened} className="multi-level">
          <div id={eventKey}>
            {children}
          </div>
        </Collapse>
      </Nav.Item>
    );
  };

  const NavItem = (props) => {
    const { title, link, target, icon: NavItemIcon, image, badgeText, badgeBg, badgeColor = "white" } = props;
    const classNames = badgeText ? "d-flex align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link as={Link} to={link} target={target} className={classNames}>
          <span>
            {NavItemIcon && (
              <span className="sidebar-icon">
                <NavItemIcon className="icon icon-xs me-2" />
              </span>
            )}

            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

            {!show && contracted && !NavItemIcon && !image ? (
              <span className="sidebar-text-contracted">
                {title[0]}
              </span>
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>

          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-sm notification-count">
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar as={Col} xs={12} expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-lg-none">
        <Navbar.Brand as={Link} to={Routes.DashboardOverview.path} className="me-lg-5">
          <Image src={LogoIcon} alt="icon-telKosh" width={40} height={40} className="sidebar-icon svg-icon" />
        </Navbar.Brand>
        <div className="d-flex align-items-center">
          <Navbar.Toggle as={Button} onClick={onCollapse}>
            <span className="navbar-toggler-icon" />
          </Navbar.Toggle>
        </div>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar {...events} className={`${contracted} ${showClass} sidebar d-lg-block bg-gray-800 text-white collapse`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="avatar-lg me-4">
                  <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h5 className="mb-3">Hi, Jane</h5>
                  <Button as={Link} variant="secondary" size="sm" to={Routes.Signin.path} className="d-inline-flex align-items-center">
                    <LogoutIcon className="icon icon-xxs me-1" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <XIcon className="icon icon-xs" />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0" >
              {contracted ?
                <Image src={LogoIcon} alt="icon-telKosh" width={40} height={40} className="sidebar-icon svg-icon mb-4" />
                :
                <div style={{ maxWidth: "210px", background: "grey", borderRadius: "10px", padding: "10px", marginBottom: "20px" }}>
                  <Image src={logo} alt="logo-telKosh" />
                </div>}

              {(userType === 'customer relationship manager' || userType === 'super_admin' || userType === 'enterprise' || userType === 'admin') && (
                // <>
                //   <CollapsableNavItem eventKey="dashboard/" title="Dashboard" icon={ChartBarIcon}>
                //     <NavItem title="Overview" link={Routes.DashboardOverview.path} />
                //     <NavItem title="All Traffic" link={Routes.DashboardTraffic.path} />
                //     <NavItem title="Product Analysis" link={Routes.DashboardProductAnalysis.path} />
                //   </CollapsableNavItem>
                // </>
                <>
                  <NavItem title="Dashboard" icon={TbLayoutDashboardFilled} link={Routes.DashboardOverview.path} />
                </>
              )}

              <>
                <CollapsableNavItem eventKey="reports/" title="Reports" icon={ChartBarIcon}>
                  <NavItem title="Campaign" link={Routes.Summary.path} />
                  <NavItem title="View CDR" link={Routes.ViewCDR.path} />
                  <NavItem title="Download Centre" link={Routes.DownloadCentre.path} />
                </CollapsableNavItem>
              </>

              {(userType === 'customer relationship manager' || userType === 'super_admin' || userType === 'admin') && (
                <>
                  <NavItem title="User Onboarding" icon={UsersIcon} link={Routes.UserOnboarding.path} />
                </>
              )}
              {(userType === 'customer relationship manager' || userType === 'super_admin' || userType === 'admin') && (
                <>
                  <NavItem title="Wallet" icon={FaWallet} link={Routes.Wallet.path} />
                </>
              )}

              {(userType === 'super_admin' || userType === 'admin') && (
                <>
                  <NavItem title="Channel Testing" icon={MdOutlineSettingsInputAntenna} link={Routes.ChannelTesting.path} />
                </>
              )}

              {(userType === 'enterprise') && (
                <>
                  <NavItem title="Contact List" icon={RiContactsBook3Fill} link={Routes.ContactList.path} />
                </>
              )}


              <CollapsableNavItem eventKey="template/" title="Templates" icon={ImInsertTemplate}>
                {(userType === 'customer relationship manager' || userType === 'super_admin' || userType === 'enterprise' || userType === 'admin') && (
                  <>
                    <NavItem title="Overview" link={Routes.Template.path} />
                  </>
                )}
                {(userType === 'enterprise') && (
                  <>
                    <NavItem title="Create Template" link={Routes.CreateTemplate.path} />
                  </>
                )}
              </CollapsableNavItem>


              <CollapsableNavItem eventKey="campaigns/" title="Campaigns" icon={MdCampaign}>
                {(userType === 'customer relationship manager' || userType === 'super_admin' || userType === 'enterprise' || userType === 'admin') && (
                  <>
                    <NavItem title="Overview" link={Routes.Campaign.path} />
                  </>
                )}
                {(userType === 'enterprise') && (
                  <>
                    <NavItem title="Create Campaign" link={Routes.CreateCampaign.path} />
                  </>
                )}
              </CollapsableNavItem>

              {(userType === 'super_admin' || userType === 'admin') && (
                <>
                  <NavItem title="Route Management" icon={CiRoute} link={Routes.RouteManagement.path} />
                </>
              )}

              {(userType === 'super_admin' || userType === 'admin' || userType === 'customer relationship manager') && (
                <>
                  <NavItem title="Message Rate" icon={FaMoneyBillTrendUp} link={Routes.MessageRate.path} />
                </>
              )}



              {/* <NavItem title="Kanban" icon={ViewGridIcon} link={Routes.Kanban.path} />
              <NavItem title="Messages" icon={InboxIcon} badgeText="4" badgeBg="danger" link={Routes.Messages.path} />
              <NavItem title="Users List" icon={UsersIcon} link={Routes.Users.path} />
              <NavItem title="Transactions" icon={CreditCardIcon} link={Routes.Transactions.path} />
              <NavItem title="Task List" icon={ClipboardListIcon} link={Routes.Tasks.path} />
              <NavItem title="Settings" icon={CogIcon} link={Routes.Settings.path} />
              <NavItem title="Calendar" icon={CalendarIcon} link={Routes.Calendar.path} />
              <NavItem title="Widgets" icon={TemplateIcon} link={Routes.Widgets.path} />

              <CollapsableNavItem eventKey="tables/" title="Tables" icon={TableIcon}>
                <NavItem title="DataTables" link={Routes.Datatables.path} />
                <NavItem title="Bootstrap Tables" link={Routes.BootstrapTables.path} />
              </CollapsableNavItem> */}

              {/* <CollapsableNavItem eventKey="examples/" title="Page Examples" icon={NewspaperIcon}>
                <NavItem title="Pricing" link={Routes.Pricing.path} />
                <NavItem title="Billing" link={Routes.Billing2.path} />
                <NavItem title="Invoice" link={Routes.Invoice.path} />
                <NavItem title="Sign In" link={Routes.Signin.path} />
                <NavItem title="Sign Up" link={Routes.Signup.path} />
                <NavItem title="Forgot password" link={Routes.ForgotPassword.path} />
                <NavItem title="Reset password" link={Routes.ResetPassword.path} />
                <NavItem title="Lock" link={Routes.Lock.path} />
                <NavItem title="404 Not Found" link={Routes.NotFound.path} />
                <NavItem title="500 Server Error" link={Routes.ServerError.path} />
              </CollapsableNavItem>

              <Dropdown.Divider className="my-3 border-indigo" />

              <CollapsableNavItem eventKey="documentation/" title="Getting Started" icon={InformationCircleIcon}>
                <NavItem title="Overview" link={Routes.DocsOverview.path} />
                <NavItem title="Download" link={Routes.DocsDownload.path} />
                <NavItem title="Quick Start" link={Routes.DocsQuickStart.path} />
                <NavItem title="License" link={Routes.DocsLicense.path} />
                <NavItem title="Folder Structure" link={Routes.DocsFolderStructure.path} />
                <NavItem title="Build Tools" link={Routes.DocsBuild.path} />
                <NavItem title="Changelog" link={Routes.DocsChangelog.path} />
              </CollapsableNavItem>

              <CollapsableNavItem eventKey="components/" title="Components" icon={ArchiveIcon}>
                <NavItem title="Accordion" link={Routes.Accordions.path} />
                <NavItem title="Alerts" link={Routes.Alerts.path} />
                <NavItem title="Badges" link={Routes.Badges.path} />
                <NavItem title="Breadcrumbs" link={Routes.Breadcrumbs.path} />
                <NavItem title="Buttons" link={Routes.Buttons.path} />
                <NavItem title="Forms" link={Routes.Forms.path} />
                <NavItem title="Modals" link={Routes.Modals.path} />
                <NavItem title="Navbars" link={Routes.Navbars.path} />
                <NavItem title="Navs" link={Routes.Navs.path} />
                <NavItem title="Pagination" link={Routes.Pagination.path} />
                <NavItem title="Popovers" link={Routes.Popovers.path} />
                <NavItem title="Progress" link={Routes.Progress.path} />
                <NavItem title="Tables" link={Routes.Tables.path} />
                <NavItem title="Tabs" link={Routes.Tabs.path} />
                <NavItem title="Toasts" link={Routes.Toasts.path} />
                <NavItem title="Tooltips" link={Routes.Tooltips.path} />
                <NavItem title="Widgets" link={Routes.WidgetsComponent.path} />
              </CollapsableNavItem>

              <CollapsableNavItem eventKey="plugins/" title="Plugins" icon={ChartPieIcon}>
                <NavItem title="Charts" link={Routes.PluginCharts.path} />
                <NavItem title="Calendar" link={Routes.PluginCalendar.path} />
                <NavItem title="DataTable" link={Routes.PluginDatatable.path} />
                <NavItem title="DropZone" link={Routes.PluginDropzone.path} />
                <NavItem title="SweetAlert" link={Routes.PluginSweetAlert.path} />
              </CollapsableNavItem> */}

              <div className="nav-item">
                <div className="nav-link" onClick={Logout}>
                  <span className="d-flex">
                    <span className="sidebar-icon me-3">
                      <BiLogOutCircle />
                    </span>
                    <span className="sidebar-text">Logout</span>
                  </span>
                </div>
              </div>
              {/* <NavItem title="Logout" icon={BiLogOutCircle} link={Routes.Presentation.path}  /> */}

            </Nav>
          </div>
        </SimpleBar >
      </CSSTransition >
    </>
  );
};
