export const overviewCards = (builder) => ({
    overviewCards: builder.mutation({
        query: ({ userId }) => {
            const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
            return {
                url: '/enterprise_overview',
                method: 'GET',
                params: { userId },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
            };
        },
    }),
});