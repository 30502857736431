export const ErrorCodes = (builder) => ({
    ErrorCodes: builder.mutation({
        query: ({ userId, startDate, endDate }) => {
            const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';
            return {
                url: '/errorCodeSummary',
                method: 'GET',
                params: { userId, startDate, endDate },
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
            };
        },
    }),
});