export const totalContactsOnly = (builder) => ({
  totalContactsOnly: builder.mutation({
    query: ({ userId }) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';  
      const userType = localStorage.getItem("userType"); // Retrieve user type
      const url = userType === "enterprise" 
        ? '/total_contacts_counts' 
        : userType === "customer relationship manager" 
        ? '/get_total_Contacts_CRM' 
        : '/total_contacts_counts'; // Optional default URL if no match
      
      return {
        url, // Dynamically set the URL
        method: 'GET',
        params: { userId },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      };
    },
  }),
});
