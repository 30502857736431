export const yearlySalesDetails = (builder) => ({
    yearlySalesDetails: builder.mutation({
      query: ({ user_id, category }) => {
        const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';  
        return {
          url: '/get_user_totalAmount',
          method: 'GET',
          params: { user_id, category },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          },
        };
      },
    }),
  });