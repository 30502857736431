
import React, { Fragment, useCallback, useEffect, useState } from "react";
// import { CloudUploadIcon, CollectionIcon, FireIcon, PlusIcon, ShieldExclamationIcon, UserAddIcon } from "@heroicons/react/solid";
// import { v4 as uuidv4 } from "uuid";

// import { CustomersWidget, RevenueWidget, UsersWidget, WeeklyReportWidget, TopAuthorsWidget, TeamMembersWidget, ProgressTrackWidget, EventsWidget, RankingWidget, VisitsMapWidget, SalesValueWidget, AcquisitionWidget, TimelineWidget, CampaignCount, CurrentBalance, BarChartSAWidget, TotalEnterpriseWidget, TotalCounts, PieChartWidget, DognutChartWidget, ChannelSummary } from "components/Widgets";
// import { PageVisitsTable } from "components/Tables";
// import { BarChartHorizontalWidget, LineGraphChartWidget } from "components/Widgets";
// import { BarChartWidget, RatingsWidget, CardWidget, ListChartWidget } from "components/Widgets";
// import ApexChart from "react-apexcharts";
// import { appRanking } from "data/charts";
import { Form, Button, InputGroup, Dropdown, Nav, Card, Table, Pagination, Col, Row} from 'react-bootstrap';
import { useGetTemplateCountMutation, useGetAllUserSAMutation, useChannelSummaryMutation, useCampaignSummaryMutation } from "Services/services";
import moment from "moment-timezone";
import { CalendarIcon, CheckIcon, CogIcon, SearchIcon } from "@heroicons/react/solid";
import Datetime from "react-datetime";

export default () => {

  //usertype
  const userData = localStorage.getItem('TOKEN');
  const userObject = JSON.parse(userData);
  const userID = userObject.id;
  const userType = userObject.user_type;

  const [tableData, setTableData] = useState([]);

  // const [bulkOption, setBulkOption] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const startDate = start ? moment(start).format("YYYY-MM-DD") : "";
  const endDate = end ? moment(end).format("YYYY-MM-DD") : "";

  const [pagelimit, setPagelimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState('');
  const [totalPosts, setTotalPosts] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');


  const [campaignSummary] = useCampaignSummaryMutation();


  // Debounce search value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(searchValue); // Set debounced value after 3 seconds
    }, 2000);
    // Cleanup the timeout if searchValue changes (to avoid multiple timeouts)
    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);


  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const response = await campaignSummary({
          user_id: userID,
          // page: currentPage,
          // limit: pagelimit,
          // search: searchValue,
          // startDate: startDate,
          // endDate: endDate,
        });
  
        if (response?.data?.statusCode === 200) {
          console.log('kjaaaaaaaaaaaaaaaaaaaaaaaaaaaa', response);
          // setTableData(response?.data?.data?.result?.data || []);
          // setTotalPages(response?.data?.data?.result?.totalPages);
          // setTotalPosts(response?.data?.data?.result?.totalPosts);
        } else {
          setTableData([]);
        }
      } catch (error) {
        alert(error.message);
        setTableData([]);
      }
    };
  
    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pagelimit, status, debouncedValue, startDate, endDate]);


  const changeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const TableRow = (props) => {
    return (
      <tr className="border-bottom">
        {userType !== 'enterprise' && <td><span className="fw-normal">{props.pe_name}</span></td>}
        <td><span className="fw-bold text-info">{props.campaign_name}</span></td>
        <td><span className="fw-normal">{props.contact_list_count}</span></td>
        <td><span className="fw-normal">{moment(props.createdAt).format('DD/MM/YYYY')}</span></td>
        <td><span className="fw-normal">{props.contact_mode}</span></td>
        <td><span className="fw-normal">{props.schedule}</span></td>
        <td><span className="fw-normal">{props.schedule}</span></td>
        <td><span className="fw-normal">{props.schedule}</span></td>
        <td><span className="fw-normal">{props.schedule}</span></td>
      </tr>
    );
  };

  return (
    <Fragment>

      <Row className="mt-3">
        <Col xs={12} xl={12}>
          <div className="table-settings">
            <Row className="justify-content-between align-items-center">
              <div className="d-flex flex-wrap">
                <InputGroup className="me-2 me-lg-3 d-flex flex-nowrap mb-3 fmxw-300">
                  <InputGroup.Text>
                    <SearchIcon className="icon icon-xs" />
                  </InputGroup.Text>
                  <Form.Control
                    type="search"
                    placeholder="Campaign Name/Enterprise"
                    value={searchValue}
                    onChange={changeSearchValue}
                  />
                </InputGroup>
                <Form.Select onChange={handleStatus} value={status} className="me-2 me-lg-3 mb-3 fmxw-300">
                  <option value="" defaultValue>All Channels</option>
                  <option value="sms">SMS</option>
                  <option value="whatsapp">WhatsApp</option>
                  <option value="rcs">RCS</option>
                </Form.Select>
                <Form.Group id="DateRange">
                  <InputGroup className="d-flex flex-nowrap">
                    <InputGroup.Text>
                      <CalendarIcon className="icon icon-xs" />
                    </InputGroup.Text>
                    <Datetime
                      timeFormat={false}
                      onChange={setStart}
                      renderInput={(props, openCalendar) => (
                        <Form.Control
                          required
                          type="search"
                          placeholder="Start Date"
                          value={startDate}
                          onFocus={openCalendar}
                          style={{ width: "150px", borderRadius: "0" }}
                          onChange={(e) => setStart(e.target.value)} />
                      )} />
                    <Datetime
                      timeFormat={false}
                      onChange={setEnd}
                      isValidDate={currDate => currDate.isAfter(start)}
                      initialViewDate={end}
                      renderInput={(props, openCalendar) => (
                        <Form.Control
                          required
                          type="search"
                          placeholder="End Date"
                          value={endDate}
                          onFocus={openCalendar}
                          style={{ width: "150px", borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
                          onChange={(e) => setEnd(e.target.value)} />
                      )} />
                  </InputGroup>
                </Form.Group>

                <Dropdown className="ms-auto mb-3">
                  <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                    <CogIcon className="icon icon-sm" />
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                    <span className="small ps-3 fw-bold text-dark">Show</span>
                    {[10, 20, 30].map((limit) => (
                      <Dropdown.Item
                        key={limit}
                        className={`d-flex align-items-center fw-bold ${limit === 30 ? 'rounded-bottom' : ''}`}
                        onClick={() => setPagelimit(limit)}
                      >
                        {limit} {pagelimit === limit && <CheckIcon className="icon icon-xxs ms-auto" />}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Row>
          </div>

          <Card border="0" className="table-wrapper table-responsive shadow">

            <Card.Body>
              <Table responsive className="align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    {userType !== 'enterprise' && <th className="border-bottom">Enterprise Name</th>}
                    <th className="border-bottom">Campaign Name</th>
                    <th className="border-bottom">Channel Name</th>
                    <th className="border-bottom">Date</th>
                    <th className="border-bottom">Total Messages</th>
                    <th className="border-bottom">Success</th>
                    <th className="border-bottom">Read</th>
                    <th className="border-bottom">Failed</th>
                    <th className="border-bottom">In-Process</th>
                  </tr>
                </thead>
                <tbody className="border-0">
                  {tableData.length > 0 ? tableData.map((u, index) => (
                    <TableRow key={index + 1} index={index + 1} {...u} />
                  )) :
                    <tr>
                      <td colSpan="8" className="text-center text-danger">No Data Found</td>
                    </tr>}
                </tbody>
              </Table>
              <Card.Footer className="px-3 border-0 d-flex flex-wrap align-items-center justify-content-between">
                <Nav>
                  <Pagination className="mb-0">
                    <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1}>
                      Previous
                    </Pagination.Prev>
                    {currentPage >= 3 && (
                      <>
                        <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                        <Pagination.Ellipsis />
                      </>
                    )}
                    {currentPage > 1 && (
                      <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                        {currentPage - 1}
                      </Pagination.Item>
                    )}
                    <Pagination.Item active>{currentPage}</Pagination.Item>
                    {currentPage < totalPages && (
                      <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                        {currentPage + 1}
                      </Pagination.Item>
                    )}
                    {currentPage < totalPages - 1 && (
                      <>
                        <Pagination.Ellipsis />
                        <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                          {totalPages}
                        </Pagination.Item>
                      </>
                    )}
                    <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages}>
                      Next
                    </Pagination.Next>
                  </Pagination>

                </Nav>
                <small className="fw-normal small mt-2 mt-md-0">
                  Showing 1 to <b>{pagelimit}</b> of <b>{totalPosts}</b> entries.
                </small>
              </Card.Footer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};


{/* ---------------------------------------------unused----------------------------------------------- */ }
{/* <Row className="justify-content-lg-center py-4">
        <Col xs={12}>
          <SalesValueWidget
            title="Sales Value"
          // value="10,567"
          // percentage={10.57}
          />
        </Col>
      </Row> */}

{/* <Row>
        <Col xs={12} className="mb-4">
          <ChannelSummary
            title="Channel-wise Message Summary"
            data={trafficVolumes}
          />
        </Col>
      </Row> */}
{/* <Row>
        <Col xs={12} md={6} xl={8} className="mb-4">
          <LineGraphChartWidget
            title="Usage Volumes by Source"
            data={trafficVolumes}
          />

          <Row>
            <Col xs={12} lg={6} className="mb-4">
              <CardWidget title="Total Contacts" />
            </Col>
            {userType === "customer relationship manager" &&
              <Col xs={12} lg={6} className="mb-4">
                <TotalEnterpriseWidget title="Total Enterprise" />
              </Col>
            }
            <Col xs={12} lg={12} xl={6} className="mb-3">
              <CampaignCount title="Campaigns Status" />
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={6} xl={4} className="mb-4">
          <Col xs={12} md={12} className="mb-4">
            <CurrentBalance title="Current Balance" />
          </Col>
          {(userType === "super_admin" || userType === "enterprise") &&
            <Col xs={12} className="mb-4">
              <TotalCounts />
            </Col>
          }
          <BarChartHorizontalWidget
            title="Template Count Category Wise"
            data={trafficShares}
          />
        </Col>
      </Row> */}

{/* <Row>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CustomersWidget
            category="Customers"
            title="345k"
            period="May 1 - June 1"
            percentage={18.2}
          />
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <RevenueWidget
            category="Revenue"
            title="$43,594"
            period="May 1 - June 1"
            percentage={-5.4}
          />
        </Col>

        <Col xs={12} sm={12} xl={4} className="mb-4">
          <UsersWidget
            category="Users"
            title="15.3k"
            period="May 1 - June 1"
            percentage={20}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} xxl={4} className="mb-4">
          <WeeklyReportWidget
            headerTitle="Weekly Sales"
            headerSubtitle="28 Daily Avg."
            reportTitle="$456,678"
            reportSubtitle="Total Themesberg Sales"
          />
        </Col>

        <Col xs={12} md={6} xxl={4} className="mb-4">
          <TopAuthorsWidget title="Top Author Earnings" />
        </Col>

        <Col xs={12} md={6} xxl={4} className="mb-4">
          <TimelineWidget title="Notifications" />
        </Col>
      </Row>

      <Row>
        <Col xs={12} xl={7} xxl={8} className="mb-4">
          <Row>
            <Col xs={12} className="mb-4">
              <PageVisitsTable />
            </Col>

            <Col xs={12} xxl={6} className="mb-4">
              <TeamMembersWidget />
            </Col>

            <Col xs={12} xxl={6} className="mb-4">
              <ProgressTrackWidget />
            </Col>

            <Col xs={12}>
              <EventsWidget />
            </Col>
          </Row>
        </Col>

        <Col xs={12} xl={5} xxl={4} className="mb-4">
          <Col xs={12} className="px-0 mb-4">
            <RankingWidget />
          </Col>

          <Col xs={12} className="px-0 mb-4">
            <AcquisitionWidget />
          </Col>

          <Col xs={12} className="px-0">
            <VisitsMapWidget />
          </Col>
        </Col>
      </Row>


      <Row>
        <Col xs={6} className="px-0">
          <ApexChart
            type="pie"
            height={360}
            series={seriesPieChart}
            options={optionsPieChart}
          />
        </Col>
      </Row> */}

