//by shreya saraswat
import React, { Fragment, useState, useEffect, useCallback } from "react";
import { CalendarIcon, CheckIcon, CloudDownloadIcon, CogIcon, DotsHorizontalIcon, HomeIcon, PlusIcon, SearchIcon, XCircleIcon } from "@heroicons/react/solid";
import { Form, Button, InputGroup, Dropdown, Nav, ToastContainer, Toast, Card, Table, OverlayTrigger, Tooltip, Pagination, Breadcrumb, Col, Row, Tab, ButtonGroup } from 'react-bootstrap';
import { useDeleteCampListMutation, useGetCampaignListMutation } from "Services/services";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Routes } from "routes";
import Datetime from "react-datetime";
import { useLocation } from "react-router-dom";
import { FcCancel } from "react-icons/fc";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary me-3',
        cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));


export default () => {
    // Get the user type from localStorage :: UI 
    const userData = localStorage.getItem('TOKEN');
    const userObject = JSON.parse(userData);
    const userType = userObject.user_type;
    const userID = userObject.id;

    // affects Tab UI only after routing from another component
    const location = useLocation();
    const [activeKey, setActiveKey] = useState(location.state?.activeTab || "SMS");
    // affects API response as well as data Table
    const [channelType, setChannelType] = useState("SMS");

    // const [showToast, setShowToast] = useState(false);
    // const [toastMessage, setToastMessage] = useState('');
    // const [toastVariant, setToastVariant] = useState('success');

    const [tableData, setTableData] = useState([]);

    // const [bulkOption, setBulkOption] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [status, setStatus] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const startDate = start ? moment(start).format("YYYY-MM-DD") : "";
    const endDate = end ? moment(end).format("YYYY-MM-DD") : "";

    const [pagelimit, setPagelimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState('');
    const [totalPosts, setTotalPosts] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');


    const [CampaignList] = useGetCampaignListMutation();
    const [deleteListApi] = useDeleteCampListMutation();



    // Debounce search value
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(searchValue); // Set debounced value after 3 seconds
        }, 2000);
        // Cleanup the timeout if searchValue changes (to avoid multiple timeouts)
        return () => {
            clearTimeout(handler);
        };
    }, [searchValue]);



    const fetchTableData = useCallback(async () => {
        try {
            const response = await CampaignList({
                user_id: (userType === 'super_admin' || userType === 'admin') ? "" : userID,
                page: currentPage,
                limit: pagelimit,
                status: status,
                search: searchValue,
                startDate: startDate,
                endDate: endDate,
                campaign_type: channelType
            });

            if (response?.data?.statusCode === 200) {
                setTableData(response?.data?.data?.result?.data || []);
                setTotalPages(response?.data?.data?.result?.totalPages);
                setTotalPosts(response?.data?.data?.result?.totalPosts);
            } else {
                setTableData([]);
            }
        } catch (error) {
            alert(error.message);
            setTableData([]);
        }
        // eslint-disable-next-line
    }, [currentPage, pagelimit, status, debouncedValue, startDate, endDate, channelType]);

    useEffect(() => {
        fetchTableData();
    }, [fetchTableData]);


    const changeSearchValue = (e) => {
        setSearchValue(e.target.value);
    };
    const handleStatus = (e) => {
        setStatus(e.target.value);
    };

    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const deleteUsers = async (ids) => {
        const usersToBeDeleted = ids;
        // const usersNr = usersToBeDeleted.length;
        const textMessage = "Are you sure do you want to delete this template?";

        const result = await SwalWithBootstrapButtons.fire({
            icon: "error",
            title: "Confirm deletion",
            text: textMessage,
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel"
        });

        if (result.isConfirmed) {
            const newUsers = tableData.filter(f => !usersToBeDeleted.includes(f.id));

            deleteListApi({ id: ids })
                .then((response) => {
                    if (response?.data?.statusCode === 200) {
                        setMessage('The List has been deleted.')
                        setTableData(newUsers);
                    } else {
                        setError('Failed to Delete.');
                    }
                })
                .catch((error) => {
                    setError('Error deleting this list.' || error);
                });

            if (message !== '') {
                await SwalWithBootstrapButtons.fire('Deleted', message, 'success');
            } else {
                await SwalWithBootstrapButtons.fire('Failed', error, 'error');
            }
        }
    }

    const downloadList = (ids) => {
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const TableRow = (props) => {
        const statusVariant = props.status === "completed" ? "success"
            : props.status === "pending" ? "purple"
                : props.status === "cancelled" ? "danger"
                    : props.status === "paused" ? "warning"
                        : "primary";

        const paymentVariant = props.payment_status === "paid" ? "success"
            : props.payment_status === "unpaid" ? "danger"
                : "primary";
        return (
            <tr className="border-bottom">
                <td><span className="fw-bold text-info">{props.campaign_name}</span></td>
                <td><span className={`fw-normal text-capitalize text-${statusVariant}`}>{props.status}</span></td>
                <td><span className={`fw-normal text-capitalize text-${paymentVariant}`}>{props.payment_status}</span></td>
                <td><span className="fw-normal">{props.contact_list_count}</span></td>
                {channelType === 'SMS' ? <td><span className="fw-normal">{props.header}</span></td> :
                    channelType === 'WhatsApp' ? <td><span className="fw-normal">{props.waba_id}</span></td> :
                        <td><span className="fw-normal">{props.header}</span></td>}
                <td><span className="fw-normal">{props.contact_mode}</span></td>
                <td><span className="fw-normal">{props.schedule}</span></td>
                <td><span className="fw-normal">{moment(props.createdAt).format('DD/MM/YYYY')}</span></td>
                <td><span className="fw-normal">{moment(props.campaign_start).format('DD/MM/YYYY')}</span></td>
                {userType !== 'enterprise' && <td><span className="fw-normal">{props.pe_name}</span></td>}
                <td>
                    <Dropdown as={ButtonGroup}>
                        <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                            <DotsHorizontalIcon className="icon icon-xs" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start py-1">
                            <Dropdown.Item className="d-flex align-items-center" onClick={() => downloadList(props.id)}>
                                <CloudDownloadIcon className="dropdown-icon text-info me-2" />
                                Download
                            </Dropdown.Item>
                            {props.status !== 'cancelled' && userType === 'enterprise' && <Dropdown.Item className="d-flex align-items-center">
                                <FcCancel className="dropdown-icon me-2" />
                                Cancel Campaign
                            </Dropdown.Item>}
                        </Dropdown.Menu>
                    </Dropdown>
                    <OverlayTrigger placement="top" overlay={<Tooltip className="m-0">Delete</Tooltip>}>
                        <Card.Link className="ms-2" onClick={() => deleteUsers(props.id)}>
                            <XCircleIcon className="icon icon-xs text-danger" />
                        </Card.Link>
                    </OverlayTrigger>
                </td>
            </tr>
        );
    };

    return (
        <Fragment>

            {/* <ToastContainer position="top-end" className="p-3">
                <Toast
                    bg={toastVariant}
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    delay={3000}
                    autohide
                >
                    <Toast.Header>
                        <strong className="me-auto">Notification</strong>
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer> */}

            <div className="d-flex align-items-center my-3">
                <div className="d-block">
                    <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                        <Breadcrumb.Item linkAs={Link}><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
                        <Breadcrumb.Item active>Campaign List</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Campaign List</h4>
                    {/* <p className="mb-0">Your SMS/WhatsApp/RCS template analytics dashboard.</p> */}
                </div>
                {userType === 'enterprise' && <Button as={Link} to={Routes.CreateCampaign.path} variant="secondary" size="sm" className="ms-auto d-inline-flex align-items-center">
                    <PlusIcon className="icon icon-xs me-2" /> New Campaign
                </Button>}
            </div>

            <Row className="mt-3">
                <Col xs={12} xl={12}>
                    <Tab.Container defaultActiveKey={activeKey} onSelect={(key) => {
                        setActiveKey(key);
                        setChannelType(key);
                    }}>
                        <Nav fill variant="pills" className="flex-column flex-sm-row">
                            <Nav.Item>
                                <Nav.Link eventKey="SMS">
                                    SMS
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="WhatsApp">
                                    WhatsApp
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="RCS">
                                    RCS
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content className="mt-0 mt-lg-3">
                            {/* <Tab.Pane eventKey="SMS"> */}
                            <div className="table-settings">
                                <Row className="justify-content-between align-items-center">
                                    <div className="d-flex flex-wrap">
                                        <InputGroup className="me-2 me-lg-3 d-flex flex-nowrap mb-3 fmxw-300">
                                            <InputGroup.Text>
                                                <SearchIcon className="icon icon-xs" />
                                            </InputGroup.Text>
                                            <Form.Control
                                                type="search"
                                                placeholder="Campaign Name/Template ID/Header"
                                                value={searchValue}
                                                onChange={changeSearchValue}
                                            />
                                        </InputGroup>
                                        <Form.Select onChange={handleStatus} value={status} className="me-2 me-lg-3 mb-3 fmxw-300">
                                            <option value="" defaultValue>All Campaigns</option>
                                            <option value="pending">Pending</option>
                                            <option value="running">Running</option>
                                            <option value="paused">Paused</option>
                                            <option value="completed">Completed</option>
                                        </Form.Select>
                                        <Form.Group id="DateRange">
                                            <InputGroup className="d-flex flex-nowrap">
                                                <InputGroup.Text>
                                                    <CalendarIcon className="icon icon-xs" />
                                                </InputGroup.Text>
                                                <Datetime
                                                    timeFormat={false}
                                                    onChange={setStart}
                                                    renderInput={(props, openCalendar) => (
                                                        <Form.Control
                                                            required
                                                            type="search"
                                                            placeholder="Start Date"
                                                            value={startDate}
                                                            onFocus={openCalendar}
                                                            style={{ width: "150px", borderRadius: "0" }}
                                                            onChange={(e) => setStart(e.target.value)} />
                                                    )} />
                                                <Datetime
                                                    timeFormat={false}
                                                    onChange={setEnd}
                                                    isValidDate={currDate => currDate.isAfter(start)}
                                                    initialViewDate={end}
                                                    renderInput={(props, openCalendar) => (
                                                        <Form.Control
                                                            required
                                                            type="search"
                                                            placeholder="End Date"
                                                            value={endDate}
                                                            onFocus={openCalendar}
                                                            style={{ width: "150px", borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
                                                            onChange={(e) => setEnd(e.target.value)} />
                                                    )} />
                                            </InputGroup>
                                        </Form.Group>

                                        <Dropdown className="ms-auto mb-3">
                                            <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                                                <CogIcon className="icon icon-sm" />
                                                <span className="visually-hidden">Toggle Dropdown</span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                                                <span className="small ps-3 fw-bold text-dark">Show</span>
                                                {[10, 20, 30].map((limit) => (
                                                    <Dropdown.Item
                                                        key={limit}
                                                        className={`d-flex align-items-center fw-bold ${limit === 30 ? 'rounded-bottom' : ''}`}
                                                        onClick={() => setPagelimit(limit)}
                                                    >
                                                        {limit} {pagelimit === limit && <CheckIcon className="icon icon-xxs ms-auto" />}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Row>
                            </div>

                            <Card border="0" className="table-wrapper table-responsive shadow">

                                <Card.Body>
                                    <Table responsive className="align-items-center table-flush">
                                        <thead className="thead-light">
                                            <tr>
                                                <th className="border-bottom">Campaign Name</th>
                                                <th className="border-bottom">Status</th>
                                                <th className="border-bottom">Payment Status</th>
                                                <th className="border-bottom">Number of Contacts</th>
                                                {channelType === 'SMS' ? <th className="border-bottom">Header</th> :
                                                    channelType === 'WhatsApp' ? <th className="border-bottom">WABA ID</th> :
                                                        <th className="border-bottom">Header</th>}
                                                <th className="border-bottom">Contact List Type</th>
                                                <th className="border-bottom">Schedule</th>
                                                <th className="border-bottom">Created Date</th>
                                                <th className="border-bottom">Campaign Start Date</th>
                                                {userType !== 'enterprise' && <th className="border-bottom">Enterprise Name</th>}
                                                <th className="border-bottom">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="border-0">
                                            {tableData.length > 0 ? tableData.map((u, index) => (
                                                <TableRow key={index + 1} index={index + 1} {...u} />
                                            )) :
                                                <tr>
                                                    <td colSpan="8" className="text-center text-danger">No Data Found</td>
                                                </tr>}
                                        </tbody>
                                    </Table>
                                    <Card.Footer className="px-3 border-0 d-flex flex-wrap align-items-center justify-content-between">
                                        <Nav>
                                            <Pagination className="mb-0">
                                                <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1}>
                                                    Previous
                                                </Pagination.Prev>
                                                {currentPage >= 3 && (
                                                    <>
                                                        <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                                                        <Pagination.Ellipsis />
                                                    </>
                                                )}
                                                {currentPage > 1 && (
                                                    <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                                                        {currentPage - 1}
                                                    </Pagination.Item>
                                                )}
                                                <Pagination.Item active>{currentPage}</Pagination.Item>
                                                {currentPage < totalPages && (
                                                    <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                                                        {currentPage + 1}
                                                    </Pagination.Item>
                                                )}
                                                {currentPage < totalPages - 1 && (
                                                    <>
                                                        <Pagination.Ellipsis />
                                                        <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                                                            {totalPages}
                                                        </Pagination.Item>
                                                    </>
                                                )}
                                                <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages}>
                                                    Next
                                                </Pagination.Next>
                                            </Pagination>

                                        </Nav>
                                        <small className="fw-normal small mt-2 mt-md-0">
                                            Showing 1 to <b>{pagelimit}</b> of <b>{totalPosts}</b> entries.
                                        </small>
                                    </Card.Footer>
                                </Card.Body>
                            </Card>
                            {/* </Tab.Pane>

                            <Tab.Pane eventKey="WhatsApp">
                                <div className="table-settings">
                                    <Row className="justify-content-between align-items-center">
                                        <div className="d-flex flex-wrap">
                                            <InputGroup className="me-2 me-lg-3 d-flex flex-nowrap mb-3 fmxw-300">
                                                <InputGroup.Text>
                                                    <SearchIcon className="icon icon-xs" />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="search"
                                                    placeholder="Campaign Name/Template ID/Header"
                                                    value={searchValue}
                                                    onChange={changeSearchValue}
                                                />
                                            </InputGroup>
                                            <Form.Select onChange={handleStatus} value={status} className="me-2 me-lg-3 mb-3 fmxw-300">
                                                <option value="" defaultValue>All Status</option>
                                                <option value="approved">Approved</option>
                                                <option value="pending">Pending</option>
                                                <option value="rejected">Rejected</option>
                                            </Form.Select>
                                            <Form.Group id="DateRange">
                                                <InputGroup className="d-flex flex-nowrap">
                                                    <InputGroup.Text>
                                                        <CalendarIcon className="icon icon-xs" />
                                                    </InputGroup.Text>
                                                    <Datetime
                                                        timeFormat={false}
                                                        onChange={setStart}
                                                        renderInput={(props, openCalendar) => (
                                                            <Form.Control
                                                                required
                                                                type="search"
                                                                placeholder="Start Date"
                                                                value={startDate}
                                                                onFocus={openCalendar}
                                                                style={{ width: "150px", borderRadius: "0" }}
                                                                onChange={(e) => setStart(e.target.value)} />
                                                        )} />
                                                    <Datetime
                                                        timeFormat={false}
                                                        onChange={setEnd}
                                                        isValidDate={currDate => currDate.isAfter(start)}
                                                        initialViewDate={end}
                                                        renderInput={(props, openCalendar) => (
                                                            <Form.Control
                                                                required
                                                                type="search"
                                                                placeholder="End Date"
                                                                value={endDate}
                                                                onFocus={openCalendar}
                                                                style={{ width: "150px", borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
                                                                onChange={(e) => setEnd(e.target.value)} />
                                                        )} />
                                                </InputGroup>
                                            </Form.Group>

                                            <Dropdown className="ms-auto mb-3">
                                                <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-1">
                                                    <CogIcon className="icon icon-sm" />
                                                    <span className="visually-hidden">Toggle Dropdown</span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-end pb-0">
                                                    <span className="small ps-3 fw-bold text-dark">Show</span>
                                                    {[10, 20, 30].map((limit) => (
                                                        <Dropdown.Item
                                                            key={limit}
                                                            className={`d-flex align-items-center fw-bold ${limit === 30 ? 'rounded-bottom' : ''}`}
                                                            onClick={() => setPagelimit(limit)}
                                                        >
                                                            {limit} {pagelimit === limit && <CheckIcon className="icon icon-xxs ms-auto" />}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Row>
                                </div>

                                <Card border="0" className="table-wrapper table-responsive shadow">

                                    <Card.Body>
                                        <Table responsive className="align-items-center table-flush">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className="border-bottom">Campaign Name</th>
                                                    <th className="border-bottom">Status</th>
                                                    <th className="border-bottom">PE Name</th>
                                                    <th className="border-bottom">PE ID</th>
                                                    <th className="border-bottom">Total Counts</th>
                                                    <th className="border-bottom">Pending</th>
                                                    <th className="border-bottom">Failed</th>
                                                    <th className="border-bottom">Template</th>
                                                    <th className="border-bottom">Template ID</th>
                                                    <th className="border-bottom">Header</th>
                                                    <th className="border-bottom">Header ID</th>
                                                    <th className="border-bottom">Contact Mode</th>
                                                    <th className="border-bottom">B-party Details</th>
                                                    <th className="border-bottom">Created By</th>
                                                    <th className="border-bottom">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="border-0">
                                                {tableData.length > 0 ? tableData.map((u, index) => (
                                                    <TableRow key={index + 1} index={index + 1} {...u} />
                                                )) :
                                                    <tr>
                                                        <td colSpan="13" className="text-center text-danger">No Data Found</td>
                                                    </tr>}
                                            </tbody>
                                        </Table>
                                        <Card.Footer className="px-3 border-0 d-flex flex-wrap align-items-center justify-content-between">
                                            <Nav>
                                                <Pagination className="mb-0">
                                                    <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1}>
                                                        Previous
                                                    </Pagination.Prev>
                                                    {currentPage >= 3 && (
                                                        <>
                                                            <Pagination.Item onClick={() => handlePageChange(1)}>1</Pagination.Item>
                                                            <Pagination.Ellipsis />
                                                        </>
                                                    )}
                                                    {currentPage > 1 && (
                                                        <Pagination.Item onClick={() => handlePageChange(currentPage - 1)}>
                                                            {currentPage - 1}
                                                        </Pagination.Item>
                                                    )}
                                                    <Pagination.Item active>{currentPage}</Pagination.Item>
                                                    {currentPage < totalPages && (
                                                        <Pagination.Item onClick={() => handlePageChange(currentPage + 1)}>
                                                            {currentPage + 1}
                                                        </Pagination.Item>
                                                    )}
                                                    {currentPage < totalPages - 1 && (
                                                        <>
                                                            <Pagination.Ellipsis />
                                                            <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                                                                {totalPages}
                                                            </Pagination.Item>
                                                        </>
                                                    )}
                                                    <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages}>
                                                        Next
                                                    </Pagination.Next>
                                                </Pagination>

                                            </Nav>
                                            <small className="fw-normal small mt-2 mt-md-0">
                                                Showing 1 to <b>{pagelimit}</b> of <b>{totalPosts}</b> entries.
                                            </small>
                                        </Card.Footer>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>

                            <Tab.Pane eventKey="RCS">
                                coming soon !
                            </Tab.Pane> */}
                        </Tab.Content>
                    </Tab.Container>
                </Col>
            </Row>



        </Fragment>
    );
};
