export const GetTemplateCount = (builder) => ({
  GetTemplateCount: builder.mutation({
    query: ({ userId }) => {
      const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';  
      const userType = localStorage.getItem("userType"); // Retrieve user type
      const url = userType === "enterprise" 
        ? '/get_template_count_category_wise' 
        : userType === "customer relationship manager" 
        ? '/get_total_template_CRM' 
        : '/get_template_count_category_wise'; // Optional default URL
      
      return {
        url, // Dynamically set the URL
        method: 'GET',
        params: { userId },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
      };
    },
  }),
});
