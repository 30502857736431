import React, { useState, useEffect } from "react";
import { CheckCircleIcon, ClockIcon, FireIcon, HomeIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Button, Breadcrumb, InputGroup, Card, Modal, ProgressBar } from 'react-bootstrap';
import Select from 'react-select';
import { useGetSMSTemplatesMutation, useGetWhatsappTemplatesMutation, useSmsTestingMutation, useWhatsAppTestingMutation } from "Services/services";

export default () => {
  const [channel, setChannel] = useState(null);
  const [templateName, setTemplateName] = useState(null);
  const [Msisdn, setMsisdn] = useState('');

  // options APIs
  const [smsTemplateList] = useGetSMSTemplatesMutation();
  const [WhatsappTemplateList] = useGetWhatsappTemplatesMutation();
  // Testing APIs
  const [smsTesting] = useSmsTestingMutation();
  const [whatsappTesting] = useWhatsAppTestingMutation();

  const [options, setOptions] = useState('');
  const [selectedChannel, setSelectedChannel] = useState(false);

  const [showAchievement, setShowAchievement] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showFailure, setShowFailure] = useState(false); // State for failure modal
  const [reason, setReason] = useState('');

  // Validation state
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (showAchievement) {
      const interval = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prevProgress + 5;
        });
      }, 100);
    }
  }, [showAchievement]);

  const selectChannelOptions = [
    { value: 'sms', label: 'SMS' },
    { value: 'whatsapp', label: 'Whatsapp' },
    { value: 'rcs', label: 'RCS' }
  ];

  const templateOptions = (selectedOption) => {
    // gives TOP-5 options for each channel type
    const channel = selectedOption.value;
    if (channel === 'sms') {
      smsTemplateList({ page: 1, limit: 5, search: '', category: '', status: '' })
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            const options = response?.data?.data?.result?.data.map(template => ({
              value: template.template_id,
              label: template.template_name
            }));
            setOptions(options);
            setSelectedChannel(true);
          } else {
            console.error('Failed to fetch user data' || response.message);
            setOptions([]);
          }
        })
        .catch((error) => {
          console.error('Error fetching user data', error);
          setOptions([]);
        });
    } else if (channel === 'whatsapp') {
      WhatsappTemplateList({ page: 1, limit: 5, search: '', category: '', status: '' })
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            const options = response?.data?.data?.result?.data.map(template => ({
              value: template.template_id,
              label: template.template_name
            }));
            setOptions(options);
            setSelectedChannel(true);
          } else {
            console.error('Failed to fetch user data' || response.message);
            setOptions([]);
          }
        })
        .catch((error) => {
          console.error('Error fetching user data', error);
          setOptions([]);
        });
    } else {
      setSelectedChannel(false);
      setOptions([]);
    }
  };

  const handleChangeChannel = (selectedOption) => {
    if (selectedOption.label === 'SMS' || 'Whatsapp') {
      setSelectedChannel(true)
    } else {
      setSelectedChannel(false)
      setValidationErrors(prevErrors => ({
        ...prevErrors,
        templateName: undefined
      }));

    }
    setChannel(selectedOption);
    templateOptions(selectedOption);
    setValidationErrors(prevErrors => ({ ...prevErrors, channel: '' }));
  };

  const handleChangeTemplate = (selectedOption) => {
    setTemplateName(selectedOption);
    setValidationErrors(prevErrors => ({ ...prevErrors, templateName: '' }));
  };

  const handleChangeMsisdn = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setMsisdn(value);
      setValidationErrors(prevErrors => ({ ...prevErrors, Msisdn: '' }));
    }
  };

  const postTesting = () => {
    const errors = {};

    // Validate fields
    if (!channel) {
      errors.channel = 'Please select a channel';
    }

    // Validate template Name
    if (!templateName) {
      errors.templateName = 'Please select a template';
    }

    // Validate MSISDN
    if (!Msisdn || Msisdn.length < 10) {
      errors.Msisdn = 'Enter a valid MSISDN';
    }

    // If there are validation errors, stop submission
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    // Clear validation errors if all fields are valid
    setValidationErrors({});

    const postData = {
      msisdn: `+91${Msisdn}`,
      template_id: templateName?.value
    };

    if (channel.value === 'sms') {
      smsTesting(postData)
        .then((success) => {
          if (success?.data?.statusCode >= 200 && success?.data?.statusCode <= 204) {
            setShowAchievement(true);
          } else {
            setShowFailure(true);
          }
        })
        .catch((error) => {
          setShowFailure(true);
        });
    }
    else if (channel.value === 'whatsapp') {
      whatsappTesting(postData)
      .then((success) => {
          if (success?.data?.statusCode >= 200 && success?.data?.statusCode <= 204) {
            setShowAchievement(true);
          } else {
            setShowFailure(true);
            setReason(success?.data?.data.error.error.reason);
          }
        })
        .catch((error) => {
          setShowFailure(true);
        });
    }
    else {
    }
  };


  const handleCloseAchievement = () => {
    setShowAchievement(false);
    setTimeout(() => {
      setProgress(0);
      resetForm();
    }, 100);
  };

  const handleCloseFailure = () => {
    setShowFailure(false);
    resetForm();
  };

  const resetForm = () => {
    setChannel(null);
    setTemplateName(null);
    setMsisdn('');
    setValidationErrors({});
    setOptions('');
    setSelectedChannel(false);
  };

  const RequiredLabel = ({ label }) => (
    <Form.Label>
      {label}
      <span className="text-danger"> * </span>
    </Form.Label>
  );

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><HomeIcon className="icon icon-xs" /></Breadcrumb.Item>
            <Breadcrumb.Item>MAT</Breadcrumb.Item>
            <Breadcrumb.Item active>Channel Testing</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Channel Testing</h4>
          <p className="mb-0">Test the channel for route messaging</p>
        </div>
      </div>

      <Card as="form" border="0" className="shadow p-3 pb-4 mb-4">
        <Card.Body className="p-0 p-md-4">
          <Row>
            <Col md={12} className="mb-3 text-left">
              <Form.Group id="channeltype">
                <RequiredLabel label="Select Channel" />
                <Select
                  options={selectChannelOptions}
                  placeholder="--Select Channel--"
                  className="mb-0"
                  isSearchable={true}
                  value={channel}
                  onChange={handleChangeChannel}
                />
                {validationErrors.channel && (
                  <small className="text-danger">{validationErrors.channel}</small>
                )}
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3 mt-3 text-left">
              <Form.Group id="templatename">
                <RequiredLabel label="Select Template" />
                <Select
                  options={options}
                  placeholder="--Select Transactional Type--"
                  className="mb-0"
                  isSearchable={true}
                  value={templateName}
                  onChange={handleChangeTemplate}
                  isDisabled={!selectedChannel}
                />
                {validationErrors.templateName && (
                  <small className="text-danger">{validationErrors.templateName}</small>
                )}
              </Form.Group>
            </Col>

            <Col xs={12} md={6} className="mb-3 mt-3 mb-lg-0">
              <Form.Group>
                <RequiredLabel label="MSISDN" />
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter MSISDN"
                    maxLength={50}
                    value={Msisdn}
                    onChange={handleChangeMsisdn}
                  />
                </InputGroup>
                {validationErrors.Msisdn && (
                  <small className="text-danger">{validationErrors.Msisdn}</small>
                )}
              </Form.Group>
            </Col>

            <Col xs={12} className="mt-4">
              <Button variant="gray-800" className="animate-up-2 mt-2" type="button" onClick={postTesting}>
                TEST
              </Button>
            </Col>
          </Row>
        </Card.Body>

        <Modal
          centered
          show={showAchievement}
          onHide={handleCloseAchievement}
          className="modal-tertiary"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="mx-auto">
            <p className="lead mb-0 text-white">Channel testing started</p>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <div className="py-3 px-5 text-center">
              {progress !== 100 ?
                (<span className="modal-icon display-1">
                  <ClockIcon className="icon icon-xl text-white" />
                </span>) :
                (<span className="modal-icon display-1">
                  <CheckCircleIcon className="icon icon-xl text-white" />
                </span>)
              }

              <Modal.Title className="text-white mb-3">
                <h3>Test Passed Successfully</h3>
              </Modal.Title>
              <p className="mb-4 text-white">
                One Thousand Dollars! Well done mate - heads are turning your way.
              </p>
              <ProgressBar variant="primary" now={progress} min={0} max={100} animated />
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center pt-0 pb-3">
            <Button variant="white" size="sm" className="text-tertiary" onClick={handleCloseAchievement}>
              Awesome!
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          centered
          show={showFailure}
          onHide={handleCloseFailure}
          className="modal-warning"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="mx-auto">
            <p className="lead mb-0 text-white">Testing Failed</p>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <div className="py-3 px-5 text-center">
              <span className="modal-icon display-1">
                <FireIcon className="icon icon-xl text-white" />
              </span>

              <Modal.Title className="text-white mb-3">
                <h3>Something went wrong</h3>
              </Modal.Title>
              <p className="mb-4 text-white">
                {reason ? reason : 'There was an issue with the channel testing. Please try again later'}.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center pt-0 pb-3">
            <Button variant="white" size="sm" className="text-warning" onClick={handleCloseFailure}>
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
      </Card>
    </>
  );
};
