export const GetCRMEnterpriseCount = (builder) => ({
    GetCRMEnterpriseCount: builder.mutation({
      query: ({ crm_id }) => {
        const token = JSON.parse(localStorage.getItem('TOKEN'))?.token || '';  
        return {
          url: '/getCRM_enterprise',
          method: 'GET',
          params: { crm_id },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          },
        };
      },
    }),
  });